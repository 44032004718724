import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import './Profile.scss'
import Icons from '../Icons'
import Grow from '../../assests/images/graph_line.svg'
import call from '../../assests/images/call.svg'
import builiding from '../../assests/images/building.svg'
import location from '../../assests/images/location.svg'
import callCircle from '../../assests/images/Secondary.svg'
import headphone from '../../assests/images/headphone.svg'
import boxtick from '../../assests/images/box-tick.svg'
import { useTranslation } from "react-i18next";

interface MainInformationData {
  label: string;
  value: string;
  src: string;
  srcccc?: string;
}

interface MainInformation {
  id: string;
  informationData: MainInformationData[];
}

const MaininformationData: MainInformation[] = [
  {
    id: "1",
    informationData: [
      { label: 'Mobile number', value: '+919737252729', src: call, },
      { label: 'Shop Name', value: 'Amit Pan Shop', src: builiding, },
      { label: 'Location', value: 'Mumbai, Maharashtra', src: location, },
    ],
  },

  {
    id: "2",
    informationData: [
      { label: 'Help & Support', value: 'Need Any Help?', src: headphone, srcccc: callCircle },
      { label: 'Sales Executive', value: 'Need to Buy Stock', src: boxtick, srcccc: callCircle },
    ],
  },
];

function Profiles() {
  const { t } = useTranslation();

  return (
    <div>
      <Box className="profile">
        <Box className='letter' sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h2">A</Typography>
        </Box>
        <Typography sx={{ fontSize: "22px", fontWeight: "600", margin: "16px 0 8px", color: "#282829" }} variant="h5">Amit Trivedi</Typography>
        <Typography sx={{ fontWeight: "400", lineHeight: "130%", color: "#696A6B", marginBottom: "20px" }} variant="body1">
          You're a star retailer, ranking among the top 20 in your city!
          {t(`profile.profile_heading`)}
        </Typography>
        <Box className='grey-flex'>
          <Box className='card'>
            <Icons name={'qaIcon2'} width={34} height={34} />
            <Typography className='caption1' variant="caption1"><span>850 Pts</span> Point balance</Typography>
          </Box>
          <Box className='card'>
            <Icons name={'qaIcon2'} width={34} height={34} />
            <Typography className='caption1' variant="caption1"><span>1000pts</span> Total Earned</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ paddingBottom: "217px" }}>
        {MaininformationData.map((info) => (
          <Box key={info.id} className="informations" sx={{ backgroundColor: "#FFF", borderRadius: "8px", margin: "16px 16px 0", padding: "0px 12px", }}>
            {info.informationData.map((info2, index) => (
              <Box key={index} className="informations-1" sx={{ display: "flex", alignItems: "center", gap: "10px", borderBottom: "1px solid lightgrey", padding: "16px 0" }}>
                <img src={info2.src} alt={info2.label} />
                <Box sx={{ textAlign: "start" }}>
                  <Typography sx={{ fontWeight: "500" }} variant="caption1">{info2.label}</Typography>
                  <Typography sx={{ fontWeight: "600", lineHeight: "normal" }} variant="body1">{info2.value}</Typography>
                </Box>
                {info.id === "2" && info2.srcccc && (
                  <Button className="secondary" sx={{ marginLeft: "auto", padding: "0", minWidth: "auto" }}>
                    <img src={info2.srcccc} alt="additional information" />
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default Profiles
