import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  '& .MuiRating-icon': {
    transition: 'color 0.2s ease',
    '&:hover': {
      opacity: 1,
      transform: 'scale(1.1)', // Slight zoom on hover
    },
  },
  '& .MuiRating-labelEmptyValueActive': {
    display:'none'
  }
}));

// Custom icons based on mood scale
const customIcons: {
  [index: number]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Not Happy',
  },
  4: {
    icon: <SentimentNeutralIcon color="warning" />,
    label: 'Neutral',
  },
  5: {
    icon: <SentimentNeutralIcon color="warning" />,
    label: 'Somewhat Neutral',
  },
  6: {
    icon: <SentimentNeutralIcon color="warning" />,
    label: 'Neutral',
  },
  7: {
    icon: <SentimentSatisfiedIcon color="success" />,
    label: 'Satisfied',
  },
  8: {
    icon: <SentimentSatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
  9: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Happy',
  },
  10: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Happy',
  },
};

// IconContainer component to display the icons based on rating
function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <Tooltip title={customIcons[value]?.label} arrow>
      <span {...other}>{customIcons[value]?.icon}</span>
    </Tooltip>
  );
}

export default function RatingQ2({
  step,
  stepIndex,
  selectedValue,
  handleChange,
}: any) {
  // Convert 'step' to an array of custom values ['1', '2', '3', '4']
  
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
    <StyledRating
      name="mood-rating"
      value={Number(selectedValue[stepIndex]?.answer) || null} // Value set from selectedValue
      sx={{width:'100%'}}
      max={step.length} // Set the max value to the length of your custom values
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value]?.label || ''}
      onChange={(_, newValue) =>
        handleChange({ target: { value: `${newValue}` } }, stepIndex)
      } // Pass the selected value
      highlightSelectedOnly
    />
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      mt={1}
      px={1}
    >
      <Typography variant="body2Medium" color="textSecondary">
        {t('taskdetaitls.feedback.notlikely')}
      </Typography>
      <Typography variant="body2Medium" color="textSecondary">
        {t('taskdetaitls.feedback.extlikely')}
      </Typography>
    </Box>
  </Box>
  );
}
