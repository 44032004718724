import React from 'react'
import Profiles from '../components/profile'
import { Box } from '@mui/material'

function Profile() {
  return (
    <div>
      <Profiles />
      <Box className="foot">
          {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
      </Box>
    </div>
  )
}

export default Profile
