import React, { useContext, useState } from "react";
import "./Competition.scss";

import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import i18n from "../../i18n";
import { t } from "i18next";
import MultiSelect from "./MultiSelect";
function Competition({ questData }: any) {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const encryptedTaskId = window.btoa(questData.data.task_id.toString());
  const [loading, setLoading] = React.useState(false);

  console.log("AvailabilityTasks", questData);
  const [answerData, setAnswerData] = React.useState<
    Array<{
      task_id: string;
      question_id: string;
      answer: string;
      no_of_attempts: string;
      is_correct: boolean;
    }>
  >([]);

  const handleChange = (newValue: any, stepIndex: any, question_id: any) => {
    const data = [...answerData];
    const noneOfTheAbove = "None of the above";
  
    if (data[stepIndex]) {
      const currentValues = data[stepIndex]?.answer
        ? data[stepIndex]?.answer.split(",")
        : [];
  
      let updatedValues;
  
      if (newValue === noneOfTheAbove) {
        // If "None of the Above" is selected, clear all other options and only select "None of the Above"
        updatedValues = currentValues.includes(noneOfTheAbove)
          ? [] // Uncheck "None of the Above" if already selected
          : [noneOfTheAbove];
      } else {
        // If another option is selected, remove "None of the Above" and toggle the current option
        updatedValues = currentValues.includes(newValue)
          ? currentValues.filter((value) => value !== newValue) // Remove if already selected
          : [...currentValues.filter((value) => value !== noneOfTheAbove), newValue]; // Add and remove "None of the Above"
      }
  
      // Update the state as a comma-separated string for the given stepIndex
      const updatedSelectedValues = [...answerData];
      updatedSelectedValues[stepIndex].answer = updatedValues.join(",");
      updatedSelectedValues[stepIndex].question_id = question_id;
      setAnswerData(updatedSelectedValues);
    } else {
      // Add new entry for the step if it doesn't exist
      data.push({
        task_id: questData.data.task_id,
        question_id: question_id,
        no_of_attempts: "1",
        is_correct: true,
        answer: newValue,
      });
      setAnswerData(data);
    }
  };
  const tasks =
    questData?.data?.subtasks?.map((subtask: any, index: number) => ({
      subtitle: subtask?.question,
      question_id: subtask?.question_id,
      task_id: subtask?.task_id,
      reward:
        questData?.data.bonus_point > 0
          ? `${t("earn_pnts.points_earn_diwali", {
              points: subtask.points || 0,
              bonus: questData?.data.bonus_point || 0,
            })}`
          : `${t("earn_pnts.points_earn", { points: subtask.points || 0 })}`,
      // reward: `${t('earn_pnts.points_earn', { points: subtask.points || 0 })}`,
      opts: subtask?.options.map((option: any) => option.option_value),
    })) || [];

  const [imagesCaptured, setImagesCaptured] = useState<boolean[]>(
    Array(tasks.length).fill(false)
  );
  const [images, setImages] = useState<
    Array<{
      task_id: string;
      question_id: string;
      answer: string;
      no_of_attempts: string;
      is_correct: string;
    }>
  >([]);

  const handleSubmit = async () => {
    // navigate("/points");
    console.log("imagesCaptured", images);
    setLoading(true);
    if (authContext) {
      const { token } = authContext;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/answer/store-answer`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": i18n.language,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(answerData),
          }
        );

        if (!response.ok) {
          setLoading(false);
          throw new Error("Failed to store answers");
        }

        const result = await response.json();
        console.log("Answers stored successfully:", result);
        navigate(`/points/${encryptedTaskId}`);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting answer", error);
      }
    }
  };

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      {/* <Box className='head' sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button onClick={() => navigate("/tasks")}><img src={back} /></Button>
          <Typography variant="body1">Variant pack pictures</Typography>
        </Box>
        <Box sx={{
          borderRadius: '40px',
          border: '1px solid #E8E8E8',
          background: '#F5F6F7',
          display: 'flex',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '4px'
        }}>
          <img src={coins} alt="" />
          <Typography variant='caption1' sx={{ fontWeight: '700' }}>{`Earn ${questData?.data?.total_points} pts`}</Typography>
        </Box>
      </Box> */}
      {/* <Box sx={{
        borderRadius: '16px',
        border: '1px solid rgba(250, 212, 102, 0.00)',
        background: 'linear-gradient(110deg, rgba(255, 248, 187, 0.00) 38.52%, rgba(220, 220, 106, 0.32) 100%), #FFF',
        paddingLeft: '16px',
        margin: '16px 20px 0 20px',
        textAlign: 'left',
        display: 'flex'
      }}>
        <Box sx={{ padding: '18px 0' }}>
          <Typography variant='caption1' sx={{ color: '#7D7E7F', letterSpacing: '0.24px', textTransform: 'uppercase', fontWeight: '600' }}>
            Task
          </Typography>
          <Typography variant='body1' sx={{ fontWeight: '600', letterSpacing: '0.16px' }}>
            // {questData?.data?.task}
            {i18n.language==="hi"?questData?.data?.task_desc_hi:questData?.data?.task_desc_en}
          </Typography>
        </Box>
        <img src={clockCutted} alt="clock" />
      </Box> */}
      {/* ))} */}
      {tasks.map((task: any, index: any) => (
        <Box
          key={index}
          sx={{
            background: "#FFFFFF",
            borderRadius: "12px",
            margin: "20px",
            textAlign: "left",
          }}
        >
          <Box
            className="cardHead12"
            sx={{
              display: "flex",
              padding: "16px 10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                whiteSpace: "pre-line",
              }}
            >
              {task.subtitle !== "" ? (
                <>
                  {`${index + 1}`} :
                  <span style={{ marginLeft: "4px" }}>
                    {task.subtitle?.replace(/\\n/g, "\n")}
                  </span>
                </>
              ) : (
                `${index + 1}`
              )}
            </Typography>
            {/* <Box sx={{
              borderRadius: '40px',
              border: '1px solid #E8E8E8',
              background: '#F5F6F7',
              display: 'flex',
              padding: '4px 8px',
              alignItems: 'center',
              gap: '4px'
            }}>
              <img src={coins} alt="" />
              <Typography variant='caption1' sx={{ fontWeight: '700' }}>{task.reward}</Typography>
            </Box> */}
          </Box>
          <Box
            sx={{
              padding: "5px 6px 16px 16px",
              gap: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {task.opts.map((step: any, stepIndex: any) => (
              <MultiSelect
                step={step}
                stepIndex={index}
                selectedValue={answerData}
                handleChange={(e: any, index: any) =>
                  handleChange(e, index, task.question_id)
                }
              />
            ))}
          </Box>
        </Box>
      ))}

      <Box className="foot">
        <Button
          type="submit"
          disabled={(answerData[0]?.answer.length || 0) === 0 || loading}
          className="btn_red"
          onClick={handleSubmit}
        >
          {/* Submit */}
          {t("taskdetaitls.availability.submit")}
          {loading ? (
            <CircularProgress
              sx={{
                color: "white",
                height: "20px !important",
                width: "20px !important",
                marginLeft: "4px",
              }}
            />
          ) : (
            ""
          )}
        </Button>
        {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
      </Box>
    </Box>
  );
}

export default Competition;
