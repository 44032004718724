import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Icons from '../components/Icons';
import TransactionsList from '../components/poinstHistory';

const RewardPoints = () => {
    const [pointsExp, setPoointsExp] = useState(false)
    return (
        <>
            <Box sx={{ background: "linear-gradient(180deg, #060000 0%, #53000F 80.13%);", padding: "13px 20px 19px" }}>
                <Box sx={{ boxShadow: "0px 0px 34px 5px #D5AC5C33", background: "rgba(213, 172, 92, 0.2)", borderRadius: "12px", marginBottom: "10px", display: "flex", padding: '18.5px 16px', width: "100%", alignItems: "center", }}>
                    <Box sx={{
                        display: "flex", alignItems: "center", textAlign: "start", gap: "16px", width: "50%",
                    }}>
                        <Icons width={33} height={33} name={'coinsSvgicon'} />
                        <Box>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "700", lineHeight: "20px" }} >1000 pts</Typography>
                            <Typography sx={{ color: "#C5C6C7", fontSize: "12px", fontWeight: "400", lineHeight: "16px" }}>Total Earned</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ background: "linear-gradient(117.62deg, #FAD466 11.17%, #947E3C 85.28%)", width: "1px", height: "32px", margin: "0px 20px 0 25px", }} />
                    <Box sx={{ display: "flex", alignItems: "center", textAlign: "start", gap: "8px", width: "50%" }}>
                        <Icons width={33} height={33} name={'coinsSvgicon'} />
                        <Box>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "700", lineHeight: "20px" }}>850 pts</Typography>
                            <Typography sx={{ color: "#C5C6C7", fontSize: "12px", fontWeight: "400", lineHeight: "16px" }}>Total Redeemed</Typography>
                        </Box>
                    </Box>
                </Box>
                {
                    !pointsExp ?
                        <Box sx={{
                            border: "1px solid #B90022", borderRadius: "8px", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center", padding: "5px 0", backdropFilter: "blur(54px)",
                        }}>
                            <Icons width={26} height={23} name={'errorIcon'} />
                            <Typography sx={{ color: "#FFFFFF", fontSize: "12px", fontWeight: "600", lineHeight: "16px" }}>
                                200 Pts are expiring in 15 days
                            </Typography>
                        </Box> : <Typography sx={{ color: "#FFFFFF", fontSize: "12px", fontWeight: "500", lineHeight: "16px" }}>Points validity is 2 years from the date of credited!</Typography>
                }
            </Box>
            <TransactionsList />
        </>
    );
};

export default RewardPoints;
