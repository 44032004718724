import React from 'react';
import './HomePage.scss'
import HomeTaskCardComponent from '../HometaskCard';
import HomeActiveTasksComponent from '../HomeActivetasks';
import { Box } from '@mui/material';
import Icons from '../Icons';
import RewordsAndVouComponent from './RewardsAndVoucherComp';

interface BoxData {
    iconName: string;
    title: string;
    subtitle: string;
    linkText: string;
}

const boxData: BoxData[] = [
    {
        iconName: 'boxTick',
        title: 'Buy Marlboro',
        subtitle: 'Contact sales',
        linkText: 'Call Us Now',
    },
    {
        iconName: 'headPhone',
        title: 'Any Queries?',
        subtitle: 'Get help if you need',
        linkText: 'Call Us Now',
    },
];

const HomePageComponent = () => {
    return (
        <>
            <Box sx={{ background: "linear-gradient(180deg, #060000 0%, #53000F 80.13%);", padding: "12px 0px 24px", borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px", }}>
                <Box sx={{ padding: "0 20px", display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                    <p className='title_text'>
                        Complete task & <p> get <span className='exc_liner'>exclusive </span>  rewards</p>
                        <Box sx={{ position: "absolute", top: "8px", right: "-8px" }}>
                            <Icons name={'starGroup'} />
                        </Box>
                    </p>
                    <Icons width={119} name={'coinCutted'} />
                </Box>
                <HomeTaskCardComponent />
            </Box>
            <Box sx={{ padding: "32px 0" }}>
                <HomeActiveTasksComponent />
            </Box>
            <RewordsAndVouComponent />
            <Box sx={{ textAlign: "start", padding: "32px 20px", width: "100%", display: "flex", gap: "16px" }}>
                {boxData.map((box, index) => (
                    <Box
                        key={index}
                        sx={{
                            background: "#fff",
                            borderRadius: "16px",
                            boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.10)",
                            padding: "12px",
                            width: "50%"
                        }}
                    >
                        <Box sx={{ background: "#FFEBEF80", borderRadius: "8px", padding: "9px", width: "48px", height: "48px", marginBottom: "8px" }}>
                            <Icons width={30} height={30} name={box.iconName} />
                        </Box>
                        <p className='buy_marlo'>{box.title}</p>
                        <p className='contact'>{box.subtitle}</p>
                        <a href="#" className='call_us'>{box.linkText}</a>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default HomePageComponent;
