import React, { useState } from "react";
import { Modal, Box, Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import { PlayCircleOutline } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 600 }, // Full width for mobile, 600px for larger screens
  maxHeight: "90vh", // Prevent overflow on very small screens
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2, // Padding adjusted for mobile
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
};

const VideoModal: React.FC<any> = ({videoUrl}:any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
     <Link
        component="button"
        onClick={handleOpen}
        sx={{ cursor: "pointer", color: "#C8172F",display: "flex", alignItems: "center" }}
        >
          <PlayCircleOutline/>
        {t("taskdetaitls.dot_code_scanning.watch_now")}
        </Link>
      {/* Modal */}
      <Modal open={open} onClose={handleClose}
        sx={{width: { xs: "100%", sm: 600 },maxHeight: "90vh",overflow: "hidden"}}
       aria-labelledby="video-modal-title">
        <Box sx={style}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex:11
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="video"
            crossOrigin="anonymous"
            controls
            sx={{
              width: "100%",
              borderRadius: "8px",
              flexGrow: 1, // Ensure the video takes up available space
              outline: "none",
            }}
          >
            <source
              src={videoUrl}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoModal;
