import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { BigPlayButton, ControlBar, PlaybackRateMenuButton, Player, PlayerState, PlayToggle } from 'video-react';
import 'video-react/dist/video-react.css';
import { Replay, ReplayCircleFilledOutlined, ReplayOutlined } from '@mui/icons-material';
// import back from '../../assests/images/back.svg'
// import coins from '../../assests/images/coins.svg'

interface VideoComponentProps {
    onVideoEnd: () => void;
    videoUrl?: string;
    posterUrl?: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ onVideoEnd,videoUrl,posterUrl }) => {
    const playerRef = useRef<Player>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isWatched,setIsWatched]=useState<boolean>(false)
    const [videoEnded,setVideoEnded]=useState<boolean>(false)
    useEffect(() => {
        const player = playerRef.current as any;
        const videoElement = player?.video.video;
        const posterElement = player?.video.video;
        if (videoElement) {
        videoElement.crossOrigin = 'anonymous';
        videoElement.poster = posterUrl;
        videoElement.style.objectFit = 'cover'; // Poster fits the container
        }
        if (player) {
            const handleStateChange = (state: PlayerState) => {
                setCurrentTime(state.currentTime);
                setDuration(state.duration);
                if (state.currentTime >= state.duration) {
                    setIsWatched(true)
                    setVideoEnded(true)
                    onVideoEnd(); 
                }
            };
            player.subscribeToStateChange(handleStateChange);
            return () => {
                player.subscribeToStateChange(() => { });
            };
        }
    }, [playerRef, onVideoEnd]);

    const handleReplay = () => {
        const ref=playerRef.current as any
        if (ref) {
          ref.seek(0);
          ref.play();
        }
        setVideoEnded(false);
      };
    const handleChange = (event: Event, newValue: number | number[]) => {
        const player = playerRef.current;
        if (player) {
            const seekTime = Array.isArray(newValue) ? newValue[0] : newValue;
            player.seek(seekTime);
            setCurrentTime(seekTime);
        }
    };

    return (
        <div style={{position:"relative"}}>
            <Player
                ref={playerRef}
                playsInline
                autoPlay
                loop={false}
                // src={videoUrl}
            >
                
                {videoEnded && (
                    <div style={{position:"absolute",transform:"translate(-50%, -50%)",top:"50%",left:"50%",zIndex:"1000"}}>
                         <ReplayOutlined sx={{height:"40px",width:"40px"}} onClick={handleReplay}>Replay</ReplayOutlined> ̰
                    </div>
                )}

                <source src={videoUrl} />
                <ControlBar className="my-class">
                    <Slider
                        value={currentTime}
                        aria-label="Current time"
                        max={duration}
                        onChange={isWatched ? handleChange : undefined}
                        className='custom_slider'
                    />
                </ControlBar>
            </Player>
        </div>
    );
};

export default VideoComponent;
