import { Box, Button } from '@mui/material';
import React from 'react';
import './HomeAct.scss'
import Icons from '../Icons';
import { useNavigate } from 'react-router-dom';

const tasks = [
    {
        id: 1,
        iconName: 'folderImage',
        taskName: 'Know our products',
        points: 200
    },
    {
        id: 2,
        iconName: 'folderImage',
        taskName: 'Click picture of Marlboro Gold Advance',
        points: 150
    },
];

const HomeActiveTasksComponent = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // height:"calc(40vh - 200px)",
                // height: "100vh",
            }}
        >
            <Box>
                <p className='active_tasks'>Active Tasks</p>

                <Box>
                    {tasks.map((task) => (
                        <div key={task.id} className='card_div'>
                            <Icons width={48} height={48} name={task.iconName} />
                            <p className='task_name'>{task.taskName}</p>
                            <div className='inner_card'>
                                <div style={{ lineHeight: "0" }}>
                                    <Icons width={18} height={18} name={'goldCoin'} />
                                </div>
                                <p className='cx_200'>X {task.points}</p>
                            </div>
                        </div>
                    ))}
                </Box>

                <Button sx={{
                    background: "#C8172F", width: "100%", borderRadius: "8px", color: "#FFF", fontSize: "14px", lineHeight: "20px", fontWeight: "500", textTransform: "none",
                    ":hover": { color: "#fff" }
                }}
                    onClick={() => navigate('/tasks')}
                >
                    View All Tasks
                </Button>

            </Box>

        </Box>
    );
};

export default HomeActiveTasksComponent;
