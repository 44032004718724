import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
} from "@mui/icons-material";
import React, {
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./stepper.scss";
import { AlertContext } from "../../context/AlertContext";
import { t } from "i18next";
import { TOTALATTEMPTS } from "../../utils/contants";
import Ratings from "./Ratings";
import RatingQ2 from "./RatingQ2";

interface StepperContainerProps {
  data: any;
  register: any;
  isStepFailed: boolean;
  watch: any;
  activeStep: any;
  setAttempts: React.Dispatch<React.SetStateAction<any>>;
  setCorrectAnswer: React.Dispatch<React.SetStateAction<any>>;
  hint: any;
}

const StepperContainer =(props:any) => {
  const { quest = "", opts = [], qno,qid } = props.data || {}
  return quest ? (
    <>
      {qno === 2 ? (
        <Box className="custonRadio1">
          <FormControl component="fieldset">
            <FormLabel component="legend">{quest}</FormLabel>
            <RadioGroup aria-label="quiz">
              {Array.isArray(opts) &&
                opts.map((va, index) => (
                  <Ratings
                    step={va}
                    stepIndex={props.index}
                    selectedValue={props.answerData}
                    handleChange={(e: any, index: any) =>
                      props.handleChange(e, index, qid)
                    }
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Box>
      ) : (
        <Box className="custonRatings">
          <FormControl component="fieldset">
            <FormLabel component="legend">{quest}</FormLabel>
             <RatingQ2
                step={opts}
                stepIndex={props.index}
                selectedValue={props.answerData}
                handleChange={(e: any, index: any) =>
                  props.handleChange(e, index, qid)
                }
              />
          </FormControl>
        </Box>
      )}
    </>
  ) : null;
};

export default StepperContainer;
