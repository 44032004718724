import React, { useState, useEffect } from "react";
import KnowYourProducts from "./KnowYourProducts";
import { useParams } from "react-router-dom";
import AvailabilityTasks from "../components/availabilityTasks/Index";
import CustomStepper from "../components/stepperv1/Stepper";
import { AuthContext } from "../context/AuthContext";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

import DotCodeScaninng from "../components/dotcodeScaninng/DotCodeScaninng";
import Feedback from "../components/Feedback/Stepper";
import Competition from "../components/Competition/Index";

const TasksDetails = (data: any) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [questData, setQuestData] = useState<any>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const authContext = React.useContext(AuthContext);

  useEffect(() => {
    const fetchTaskData = async () => {
      if (authContext && id) {
        const { token } = authContext;
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/task/fetch-task-details/${window.atob(id)}`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                'Accept-Language': i18n.language,
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            setQuestData(null)
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          window.parent.postMessage({ action: 'changeLabel', label: data?.data?.task || "" }, '*');
          // window.parent.postMessage({ action: 'task_points', label: `${t('earn_pnts.points_earn', { points: data?.data?.total_points || 0 })}` || "" }, '*');

          setQuestData(data);

          const fetchedTaskId = data.data?.task_id;
          if (fetchedTaskId) {
            setTaskId(fetchedTaskId);
          }
        } catch (error) {
          console.error("Failed to fetch task data:", error);
        }
      }
    };
    if (id && id !== null) fetchTaskData();
  }, []);

  console.log("questData:", questData, id);
  console.log("taskId:", taskId);
  const TaskTypee = questData?.data?.task_type;

  return (
    <>
      {questData !== null && (
        TaskTypee === t(`taskdetaitls.educational.type`) ? (

        <CustomStepper questData={questData} />
      ):
        TaskTypee === t(`taskdetaitls.feedback.type`) ? (
        <Feedback questData={questData} />
      ) : TaskTypee === t(`taskdetaitls.competition.type`) ? (
        <Competition questData={questData} />
      ):(TaskTypee === t(`taskdetaitls.dot_code_scanning.type`) ?(
        <div style={{ minHeight: "100%" }}>
        {/* <div style={{ minHeight: "calc(100vh - 254px)",overflowY:"scroll" }}> */}
        <DotCodeScaninng questData={questData} />
      </div>
      ): (
        <div style={{ height: "100%" }}>
        {/* <div style={{ height: "calc(100vh - 254px)" }}> */}
          <AvailabilityTasks questData={questData} />
          {/* <AvaliabilityCustomStepper questData={questData} /> */}
        </div>
      )))}
    </>
  );
};

export default TasksDetails;
