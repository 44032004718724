import React from 'react';
import './TransactionsLists.scss';
import { Box, Button, Drawer, Typography } from '@mui/material';
import Icons from '../Icons';

type Transaction = {
    title: string;
    date: string;
    status?: string;
    points: number;
};
type PointsCardsProps = {
    transactions: Transaction[];
};

const PointsCards: React.FC<PointsCardsProps> = ({ transactions }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedTransaction, setSelectedTransaction] = React.useState<Transaction | null>(null);

    const toggleDrawer = (open: boolean, transaction?: Transaction) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setSelectedTransaction(transaction || null);
        setIsOpen(open);
    };

    return (
        <>
            <Box sx={{ padding: "0 20px", overflowY: "scroll", height: "calc(100vh - 235px)" }}>
                {transactions.map((transaction, index) => (
                    <Box key={index} sx={{ display: 'flex', borderBottom: "1px solid #d7d7d7", padding: "12px 0px" }}>
                        <Box
                            sx={{
                                background: transaction.title === "Task Completed" ? '#F5F6F7' : '#FFF0EA',
                                padding: "9px", borderRadius: "50%", width: "42px", height: "42px", marginRight: "10px"
                            }}
                        >
                            <Icons width={24} height={24} name={transaction.title === "Task Completed" ? "verify" : "ticketStar"} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: "center", width: "100%" }}>
                            <Box sx={{ textAlign: "start" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "20px", color: "#282829" }}>
                                    {transaction.title}
                                </Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "16px", color: "#696A6B" }}>
                                    {transaction.date}
                                </Typography>
                                {(transaction.title === "Task Completed" || transaction.status) && (
                                    <Box
                                        onClick={toggleDrawer(true, transaction)}
                                        sx={{
                                            fontSize: transaction.status ? "12px" : "14px",
                                            cursor: "pointer",
                                            color: transaction.status ? "#DD7A00" : "#C8172F",
                                            lineHeight: transaction.status ? "16px" : "20px",
                                            fontWeight: transaction.status ? "600" : "500",
                                            marginTop: "6px",
                                            padding: transaction.status ? "6px" : "0",
                                            background: transaction.status ? "#FFF0EA" : "transparent",
                                            borderRadius: transaction.status ? "8px" : "0"
                                        }}
                                    >
                                        {transaction.status || "View Details"}
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ marginLeft: "auto" }}>
                                <Icons width={16} height={16} name={'brokenCoin'} />
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "16px",
                                        color: transaction.points > 0 ? "#2E844A" : "#B90022"
                                    }}
                                >
                                    {transaction.points > 0 ? `+${transaction.points} Pts` : `${transaction.points} Pts`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                sx={{ ".MuiPaper-elevation": { borderRadius: "20px 20px 0 0", overflowY: "unset" } }}
            >
                <Box onClick={toggleDrawer(false)} sx={{
                    position: "absolute", top: "-20px", right: "50%",
                    transform: "translate(50% ,-50%)", cursor: "pointer"
                }}>
                    <Icons width={24} height={24} name={'closeIcon'} />
                </Box>

                <Box sx={{ padding: "20px 17px 0px" }}>
                    {selectedTransaction && selectedTransaction.title === "Task Completed" && (
                        <Box sx={{ textAlign: "center" }}>
                            <Icons width={68} height={68} name={'fileDicon'} />
                            <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "600", color: "#282829", marginBottom: "2px" }}>Task Completed</Typography>
                            <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500", color: "#7D7E7F", marginBottom: "16px" }}>{selectedTransaction.date}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: "16px", marginBottom: "16px" }}>
                                <Box sx={{ display: "flex", width: "50%", alignItems: "center", background: "#FFF0E1", padding: "10px", borderRadius: "12px", gap: "3px" }}>
                                    <Icons width={30} height={30} name={'penDicon'} />
                                    <Box>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "18.2px", fontWeight: "600", color: "#282829" }}>+{selectedTransaction.points}/400 Pts</Typography>
                                        <Typography sx={{ fontSize: "12px", lineHeight: "15.6px", fontWeight: "500", color: "#494A4B" }}>Points Earned</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "50%", alignItems: "center", background: "#F9FBFC", padding: "10px", borderRadius: "12px", gap: "3px" }}>
                                    <Icons width={30} height={30} name={'coinDicon'} />
                                    <Box>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "18.2px", fontWeight: "600", color: "#282829" }}>2/2 Questions</Typography>
                                        <Typography sx={{ fontSize: "12px", lineHeight: "15.6px", fontWeight: "500", color: "#494A4B" }}>Answered correctly</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ border: "1px solid #F1F2F3", borderRadius: "12px", padding: "14px 10px", fontSize: "12px", lineHeight: "16px", fontWeight: "500", color: "#282829" }}>
                                <span style={{ fontSize: "12px", lineHeight: "16px", fontWeight: "700", color: "#282829" }}>Note: </span>Earned points valid till {selectedTransaction.date}
                            </Box>
                        </Box>
                    )}
                    {selectedTransaction && selectedTransaction.status && (
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ background: "#FFF0EA", borderRadius: "50%", width: "68px", height: "68px", display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto 12px" }}>
                                <Icons width={36.86} height={36.86} name={'ticketStar'} />
                            </Box>
                            <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "600", color: "#282829", marginBottom: "2px" }}>{selectedTransaction.title}</Typography>
                            <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500", color: "#7D7E7F", marginBottom: "16px" }}>{selectedTransaction.date}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", textAlign: "start", width: "100%", background: "#F9FBFC", padding: "10px", gap: "16px" }}>
                                <Box sx={{ width: "50%" }}>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "16px", color: selectedTransaction.points > 0 ? "#2E844A" : "#B90022" }}>
                                        {selectedTransaction.points > 0 ? `+${selectedTransaction.points} Pts` : `${selectedTransaction.points} Pts`}
                                    </Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15.6px", color: "#494A4B" }}>
                                        {selectedTransaction.points > 0 ? `Points Credited` : `Points Debited`}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px", color: "#DD7A00" }}>
                                        {selectedTransaction.status}
                                    </Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15.6px", color: "#494A4B" }}>
                                        Transaction Status
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Button onClick={toggleDrawer(false)} sx={{
                    color: "#FFFFFF", fontSize: "14px", fontWeight: "500", lineHeight: "20px",
                    background: "#C8172F", margin: "16px 20px", borderRadius: "8px", textTransform: "initial",
                    '&:hover': { background: '#C8172F', color: '#FFF' }
                }}>
                    Okay!
                </Button>
            </Drawer>
        </>
    );
};

export default PointsCards;
