import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import Instruction from "./Instruction";

function DotCodeScaninng({ questData }: any) {
  const [taskData, setTaskData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasReadInstruction, sethHasReadInstruction] = useState(false);
  const navigate = useNavigate();

  // Function to hide loader once iframe loads
  const handleIframeLoad = () => {
    setIsLoading(false);
  };
const generateUrl=async(questData:any)=>{

  const requestOptions:any = {
    method: "POST",
    redirect: "follow"
  };
  await fetch(`${process.env.REACT_APP_WSA_API_URL}/wsa/api/auth/generate-token?secret_key=${process.env.REACT_APP_WSA_KEY}`, requestOptions)
  .then((response) => response.json())
  .then((result:any) => {
    console.log(result,result.auth_token,"result token")
    if(result){
      const url = `${process.env.REACT_APP_WSA_STATIC_URL}/wsa/app?languageId=${i18n.language.includes('hi')?2:1}&token=${result.auth_token}&published_id=${questData.data.publish_id.id}`;
      // alert(url)
      setTaskData(url); 
    }else{
      throw new Error("Failed to open task")
    }
    throw new Error("Failed to open task")
  
  })
  .catch((error) => {
    console.error(error)
    throw new Error("Failed to open task")

  });
}
  useEffect(() => {
    if (hasReadInstruction && questData) {
      setIsLoading(true)
      generateUrl(questData)
    // Build the iframe URL using questData
      // console.log("questDataquestData",questData)
      // const url = `https://dev.mitrapp2.com/wsa/app?languageId=${i18n.language.includes('hi')?2:1}&token=e333ee9e974093f0a5e156ede4e50b29&published_id=${questData.data.publish_id.id}`;
      // setTaskData(url); // Set iframe URL once it's constructed
    }
  }, [hasReadInstruction,questData]);



  // Event listener for messages from iframe
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      console.log("event_coming here", event);
      if (event.data.type === "redirect") {
        const encryptedTaskId = window.btoa(questData.data.task_id.toString());
        navigate(`/points/${encryptedTaskId}`);
      }
    };

    // Add event listener
    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("message", handleMessage);
  }, [navigate]);


  return (
    <>
      {isLoading && <div className="loader">Loading...</div>}
      {!hasReadInstruction?
        <Instruction questData={questData} sethHasReadInstruction={(val)=>sethHasReadInstruction(val)}/>
      :taskData && (
        <iframe
          src={taskData}
          allow="camera; microphone"
          onLoad={handleIframeLoad}
          style={{
            width: "100vw",
            minHeight: "170vh",
            display: isLoading ? "none" : "block",
            border: "none",
          }}
          title="Dot Code scanning"
        />
      )}
    </>
  );
}

export default DotCodeScaninng;
