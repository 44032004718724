import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getsession,
  getTaskDetails,
} from "../utils/apis";
import {
  KEY_AUTH_DETAILS,
  KEY_BILLINGCITY,
  KEY_LANG,
} from "../utils/contants";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const AuthWrapper: React.FC<{ children: ReactNode, adjustHeight?: boolean; }> = ({ children, adjustHeight }) => {
  const location = useLocation();
  const navigate = useNavigate()

  const [Loding, setLoding] = useState(true);
  const [authenticated, setAthenticated] = useState(false);
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params: any = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    if(params["clientHeight"] && params["clientHeight"]!==null){
      document.documentElement.style.minHeight = `${params["clientHeight"]}px`;
      document.body.style.minHeight = `${params["clientHeight"]}px`;
    }
    if (params["query"] && params["query"] !== null) {
      // window.parent.postMessage({ action: 'secretToken', label:  params['query']}, '*');

      getsession(params["query"])
        .then(async (result) => {
          console.log("resultresult", result);
          const { token, data } = result;
          const accId = data?.User?.Contact?.AccountId || undefined;
          const contactId = data?.User?.ContactId || undefined;
          const city = data?.User?.Contact?.Account?.BillingAddress?.city || undefined;
          window.parent.postMessage({ action: 'storeToLocal', label: city }, '*');

          const lang = data?.User?.LanguageLocaleKey || undefined;
          if (token && accId) {
            authContext?.setSession(token);
            localStorage.setItem(KEY_AUTH_DETAILS, accId);
            localStorage.setItem(KEY_LANG, lang);
            localStorage.setItem(KEY_BILLINGCITY, city);
            i18n.changeLanguage(lang)
            await getTaskDetails(contactId).then((res2) => {
              if (
                res2.data &&
                Array.isArray(res2.data) &&
                res2.data.length > 0
              ) {
                setAthenticated(true);
                setLoding(false);
                const { task_id } = res2.data[0];
                // navigate(`/tasks/${window.btoa(task_id)}`)
              } else {
                navigate(`/tasks`)
                setLoding(false);
              }
              console.log(res2, "resultData");
            });
          }
        })
        .catch((errr) => {
          console.log(errr);
          setAthenticated(false);
          setLoding(false);
        });
    } else {
      const tokens = authContext?.token;
      if (tokens && tokens !== null) {
        setAthenticated(true);
        setLoding(false);
      } else {
        setAthenticated(false);
        setLoding(false);
      }
    }
    console.log("parama", params["query"]);
  }, [location]);

  return (
    <>
      {Loding ? (
        <div>Authenticating....</div>
      ) : authenticated ? (
        // <div
        //   style={{ height: adjustHeight ? "calc(100vh - 254px)" : "auto" }}>
        //   {children}</div>
        <div>
          {children}</div>
      ) : (
        <div>Un Authenticated</div>
      )}
    </>
  );
};
export default AuthWrapper;
