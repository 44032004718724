import { Box, Button, Drawer, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import './TransactionsLists.scss';
import PointsCards from './pointsCards';
import Icons from '../Icons';
import { useState } from 'react';

interface Transaction {
    title: string;
    date: string;
    status?: string;
    points: number;
}

const transactions: Transaction[] = [
    { title: 'Croma gift card', date: '12 Oct 2021, 03:30 PM', status: 'Pending for Approval', points: -200 },
    { title: 'Marlboro voucher', date: '12 Oct 2022, 03:30 PM', points: -200 },
    { title: 'Task Completed', date: '10 Oct 2021, 02:00 PM', points: 350 },
    { title: 'Points Expired', date: '12 Oct 2022, 03:30 PM', points: -200 },
    { title: 'Marlboro voucher', date: '12 Oct 2022, 03:30 PM', points: -200 },
    { title: 'Task Completed', date: '10 Oct 2021, 02:00 PM', points: 150 },
    { title: 'Task Completed', date: '10 Oct 2023, 02:00 PM', points: 150 },
    { title: 'Points Expired', date: '12 Oct 2024, 03:30 PM', points: -50 },
    { title: 'Marlboro voucher', date: '12 Oct 2022, 03:30 PM', status: 'Pending for Approval', points: -300 },
    { title: 'Task Completed', date: '10 Oct 2024, 02:00 PM', points: 150 },
];

const TransactionsList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState<string>('');
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>(transactions);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const handleMonthChange = (event: SelectChangeEvent<string>) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (year: string) => {
        setSelectedYear(year);
    };

    const applyFilter = () => {
        const newFilteredTransactions = transactions.filter((transaction) => {
            const transactionDate = new Date(transaction.date);
            const transactionYear = transactionDate.getFullYear().toString();
            const transactionMonth = transactionDate.toLocaleString('default', { month: 'long' });

            return (
                (selectedYear ? transactionYear === selectedYear : true) &&
                (selectedMonth ? transactionMonth === selectedMonth : true)
            );
        });

        setFilteredTransactions(newFilteredTransactions);
        setIsOpen(false);
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, index) => (currentYear - index).toString());


    return (
        <>
            <div className='transactions-date'>
                <div className='transactions-div' style={{ maxWidth: "168px" }}>
                    <p className='transactions-text'>
                        Transactions
                    </p>
                    <p className='results'>
                        {filteredTransactions.length} Results from last 3 months
                    </p>
                </div>
                <Box onClick={toggleDrawer(true)} sx={{
                    cursor: "pointer",
                    border: '1px solid #E7E8E9', borderRadius: "8px", padding: "8px", display: "flex", alignItems: "center", gap: "6px", marginLeft: "auto"
                }}>
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        color: "#282829",
                    }}>
                        {!selectedYear || !selectedMonth ? 'Filter by Month' : `${selectedMonth.slice(0, 3)} ${selectedYear}`}
                    </Typography>
                    <Icons width={20} height={20} name={"arrowDown"} />
                </Box>
            </div>
            <PointsCards transactions={filteredTransactions} />
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                sx={{ ".MuiPaper-elevation": { borderRadius: "20px 20px 0 0", overflowY: "unset" } }}
            >
                <Box onClick={toggleDrawer(false)} sx={{
                    position: "absolute", top: "-20px", right: "50%",
                    transform: "translate(50% ,-50%)", cursor: "pointer"
                }}>
                    <Icons width={24} height={24} name={'closeIcon'} />
                </Box>
                <Box sx={{ padding: "20px 17px 0px" }}>
                    <Typography sx={{
                        color: "#282829", fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px", marginBottom: "16px", borderBottom: "1px solid #E7E8E9", paddingBottom: "10px"
                    }}> Filter by Month</Typography>
                    <Typography sx={{
                        color: "#282829", fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px", marginBottom: "16px"
                    }}>Select Year</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "14px", marginBottom: "16px" }}>
                        {years.map((year) => (
                            <Typography
                                key={year}
                                onClick={() => handleYearChange(year)}
                                sx={{
                                    color: selectedYear === year ? "#B90022" : "#494A4B",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    cursor: "pointer",
                                }}
                            >
                                {year}
                            </Typography>
                        ))}
                    </Box>
                    <Typography sx={{
                        color: "#282829", fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px", marginBottom: "16px"
                    }}>Select Month</Typography>

                    <FormControl fullWidth>
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            sx={{
                                color: "#282829", fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                outline: "none",
                                ".MuiSelect-select": {
                                    padding: "14px 8px"
                                    , borderRadius: "8px",
                                    border: "1px solid #E7E8E9",
                                    outline: "unset"
                                },
                            }}
                        >
                            <MenuItem value="" sx={{
                                color: "#282829", fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                            }}>
                                <em>None</em>
                            </MenuItem>
                            {months.map((month) => (
                                <MenuItem key={month} value={month} sx={{
                                    color: "#282829", fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                }}>{month}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Button onClick={applyFilter} disabled={!selectedYear || !selectedMonth} sx={{
                    color: "#FFFFFF", fontSize: "14px", fontWeight: "500", lineHeight: "20px",
                    background: "#C8172F", margin: "16px 20px", borderRadius: "8px", textTransform: "initial",
                    '&:hover': { background: '#C8172F', color: '#FFF' }, ":disabled": { background: "#C8172F", opacity: "0.5" }
                }}>
                    Apply
                </Button>
            </Drawer>
        </>
    );
};

export default TransactionsList;
