import React from 'react';
import HomeActiveTasksComponent from '../components/HomeActivetasks';

const HomeActiveTasks = () => {
    return (
        <>
            <HomeActiveTasksComponent />
        </>
    );
};

export default HomeActiveTasks;
