import i18n from "../i18n";
export const getResults = async (task_id: any, token: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/task/task-result/${task_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language':i18n.language,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch task results");
    }

    const data = await response.json();
    console.log("Task Result:", data);
    return data.data;
  } catch (error) {
    console.error("Error fetching task results:", error);
    throw error;
  }
};

export const getsession = async (queryparams: any,userAgent?:any) => {
  return await fetch(
    `${
      process.env.REACT_APP_BASE_URL
    }/auth/get-session-data?encryptedData=${encodeURIComponent(queryparams)}`,
    { 
      method: "get",
      headers:{
        'user-agent':userAgent
      }
     }
  )
    .then((response) => response.json())
    .then((result: any) => {
      console.log("imgg", result);
      return result;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error?.message || "Something went wrong");
    });
};
export const getTaskDetails = async (accId: any,lang?:string) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/task/fetch-tasks?account_id=${accId}`,
    {
      method: "GET",
      headers: {
        'Accept-Language':lang?lang:i18n.language,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((result: any) => {
      console.log("imgg", result);
      return result;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error?.message || "Something went wrong");
    });
};

export const getTaskList = async (token: string,lang?:string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/task/get-tasks-v1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        'Accept-Language':lang?lang:i18n.language,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch task results");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching task results:", error);
    throw error;
  }
};
