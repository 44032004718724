import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function InvisibleIframe() {

    const location = useLocation(); 
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const params: any = {};
        queryParams.forEach((value, key) => {
          params[key] = value;
        });
        if (params["query"] && params["query"] !== null) {
          window.parent.postMessage({ action: 'secretToken', label: params['query'],height:window.innerHeight}, '*');
        }
        console.log("parama", params["query"],);
      }, [location]);
      
  return (
    <div style={{display:'none'}}>InvisibleIframe</div>
  )
}

export default InvisibleIframe