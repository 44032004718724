import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

interface CameraProps {
    data: any;
    defaultImage: any;
    setImages: React.Dispatch<React.SetStateAction<any>>;
    images: any;
    onCapture: (captured: boolean) => void;
}

const CameraCapture: React.FC<CameraProps> = ({ data, defaultImage, onCapture, setImages, images }) => {
    const [image, setImage] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0];
            if (file) {
                const imageUrl = URL.createObjectURL(file);
                setImage(imageUrl);

                const formdata = new FormData();
                formdata.append("answer", file);

                setIsUploading(true);
                setUploadProgress(0);

                fetch(`${process.env.REACT_APP_BASE_URL}/answer/upload`, {
                    method: "POST",
                    body: formdata,
                })
                    .then((response) => response.json())
                    .then((result: any) => {
                        if (result.data.answer) {
                            const imagesData = {
                                task_id: data.task_id,
                                question_id: data.question_id,
                                answer: result.data.answer,
                                no_of_attempts: "1",
                                is_correct: "1",
                            };

                            setImages((prevImages: any) => {
                                const imgDataCopy = [...prevImages];
                                const isDataExist = imgDataCopy.findIndex(
                                    (item) => item.question_id === data.question_id
                                );

                                if (isDataExist !== -1) {
                                    imgDataCopy[isDataExist] = imagesData; // Update existing entry
                                } else {
                                    imgDataCopy.push(imagesData); // Add new entry
                                }

                                return imgDataCopy;
                            });

                            onCapture(true);
                        }
                    })
                    .catch((error) => console.error(error))
                    .finally(() => {
                        setIsUploading(false);
                        setUploadProgress(0);
                    });
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    return (
        <Box sx={{ position: 'relative', padding: '12px 16px' }}>
            {/* Overlay Section */}
            {isUploading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 10,
                        borderRadius: '16px',
                    }}
                >
                    <CircularProgress />
                    <Typography
                        sx={{ mt: 2 }}
                        variant="body2"
                    >
                        {t('taskdetaitls.availability.uploading')}
                    </Typography>
                </Box>
            )}

            {/* Main Content */}
            {image ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '16px',
                        background: '#F5F6F7',
                    }}
                >
                    <img
                        style={{
                            width: "100%",
                            borderRadius: "16px",
                            height: "330px",
                            objectFit: "contain",
                        }}
                        src={image}
                        alt="Captured"
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '16px',
                        background: '#F5F6F7',
                    }}
                >
                    <img
                        style={{
                            objectFit: "cover",
                        }}
                        crossOrigin="anonymous"
                        src={defaultImage}
                        alt="Default"
                        width="100%"
                    />
                </Box>
            )}

            {!isUploading && (
                <Button
                    className="btnRed"
                    sx={{
                        color: image ? '#C8172F' : '#FFF',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '21px',
                        letterSpacing: '0.12px',
                        borderRadius: '8px',
                        border: "1px solid #C8172F",
                        background: image ? '#FFF' : '#C8172F',
                        height: '32px',
                        padding: '1px 12px',
                        marginTop: '8px',
                        width: '100%',
                        textTransform: 'inherit',
                        '&:hover': {
                            background: '#C8172F',
                            color: '#FFF',
                        },
                    }}
                    component="label"
                    disabled={isUploading}
                >
                    {isUploading
                        ? t('taskdetaitls.availability.uploading_btn')
                        : image
                            ? t('taskdetaitls.availability.retake_photo_btn')
                            : t('taskdetaitls.availability.take_photo_btn')}
                    <input
                        type="file"
                        accept="image/*"
                        capture="environment"
                        style={{ display: 'none' }}
                        onChange={handleCapture}
                    />
                </Button>
            )}
        </Box>
    );
};

export default CameraCapture;
