import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";

import './brandSlick.scss'

const brands = [
    { src: '/images/BannerImggg.png', alt: "Amazon" },
    { src: '/images/BannerImggg.png', alt: "Big Basket" },
    { src: '/images/BannerImggg.png', alt: "Book My Show" },
];

const BrandSlick: React.FC = () => {
    const settings = {
        className: "BrandSlick-class",
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        adaptiveHeight: true
    };

    return (
        <div>
            <Slider {...settings}>
                {brands.map((brand, index) => (
                    <div key={index}>
                        <Box
                            sx={{
                                margin: "0 6px",
                                textAlign: "center",
                            }}
                        >
                            <img
                                src={brand.src}
                                alt={brand.alt}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                    maxWidth: "335px"
                                }}
                            />
                        </Box>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default BrandSlick;
