import { Box } from '@mui/material';
import React from 'react';
import Icons from '../Icons';
import './HomePage.scss';

interface Voucher {
    id: number;
    discount: string;
    offer: string;
    points: number;
    iconName: string;
}

const vouchers: Voucher[] = [
    {
        id: 1,
        discount: '₹300 Off',
        offer: 'Shopping voucher',
        points: 200,
        iconName: 'amazonIcon'
    },
    {
        id: 2,
        discount: '₹500 Off',
        offer: 'Shopping voucher',
        points: 300,
        iconName: 'myntraIcon'
    }
];

const VoucherCards: React.FC = () => {
    return (
        <>
            {vouchers.map((voucher) => (
                <Box
                    key={voucher.id}
                    sx={{
                        border: "1px solid rgba(255, 255, 255, 0.40)",
                        padding: "16px",
                        background: "rgba(255, 255, 255, 0.16)",
                        boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.10)",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        borderRadius: "8px",
                        marginBottom: "16px",
                    }}
                >
                    <Icons width={40} height={40} name={voucher.iconName} />
                    <Box>
                        <p className='cost_off'>{voucher.discount}</p>
                        <p className='shop_offer'>{voucher.offer}</p>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            marginLeft: "auto",
                            borderRadius: "4px",
                            border: "1px solid #FFF",
                            background: "#77565B",
                            padding: "4px 8px"
                        }}
                    >
                        <Icons name={'roundLock'} />
                        <p className='Unclock_pnts'>Unlock {voucher.points} pts</p>
                    </Box>
                </Box>
            ))}
        </>
    );
};

export default VoucherCards;
