import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step, { StepProps } from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  StepConnector,
  stepConnectorClasses,
  StepIcon,
} from "@mui/material";
import styled from "@emotion/styled";
import StepperContainer, { StepperContainerHandle } from "./StepperContainer";
import { useForm } from "react-hook-form";
import "./stepper.scss";
import HoldOn from "./HoldOn";
import CorrectAnswer from "./CorrectAnswer";
import { Cancel } from "@mui/icons-material";
import { css } from "@emotion/react";
// import back from '../../assests/images/back.svg'
import BannerImg from "../../assests/images/bannerImg.svg";
import VideoComponent from "../videos";
import NoteCoin from "../videos/NoteCoin";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Icons from "../Icons";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const QontoConnector = styled(StepConnector)(({ theme }: any) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
    zIndex: "-1",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#FFEBEF",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const StyledStepper = styled(Stepper)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));
interface StyledStepProps extends StepProps {
  error?: boolean; // Add the error prop as optional
}

const StyledStep = styled(Step)<StyledStepProps>(({ theme, error }) =>
  css({
    padding: "0 !important",
    ".MuiSvgIcon-root": {
      borderRadius: "30px",
      border: "1px solid #C5C6C7",
      background: "#F5F6F7",
      color: "transparent",
      "&.Mui-active": {
        borderRadius: "30px",
        border: "1px solid #C8172F",
        background: "#FFEBEF",
        color: "transparent",
        ".MuiStepIcon-text": {
          fill: "#C8172F",
        },
      },
      "&.Mui-completed": {
        color: "#46C65A",
        border: "0",
      },
      ".MuiStepIcon-text": {
        fill: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.12px",
      },
    },
    ".MuiStepLabel-labelContainer": {
      ".MuiStepLabel-label": {
        color: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.12px",
        marginTop: "4px",
        ".points_count": {
          display: "block",
          color: "#7D7E7F",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "18px",
          letterSpacing: "0.12px",
          ".error": {
            color: "#C8172F",
          },
        },
        ".error": {
          color: "#C8172F",
        },
      },
    },
    // Apply conditional styles based on the error prop
    ...(error && {
      ".MuiSvgIcon-root": {
        color: "#C8172F",
      },
      ".MuiStepLabel-labelContainer .MuiStepLabel-label": {
        color: "#C8172F", // Change label color if there's an error
      },
    }),
  })
);

const StyledStepLabel = styled(StepLabel)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    // Custom styles for step labels in mobile
    fontSize: "1rem",
  },
}));

export default function CustomStepper({ questData }: any) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const TOTALATTEMPTS = questData?.data?.location==="Mumbai"?2:4;

  const [skipped, setSkipped] = React.useState(new Set<number>());
  const { register, handleSubmit, watch, formState } = useForm<any>();
  const [attempts, setAttempts] = React.useState<any>(0);
  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false);
  const [startquiz, setStartquiz] = React.useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = React.useState<null | any>(null);
  const [isStepFailed, setIsStepFailed] = React.useState<Array<number | null>>(
    []
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const [isVideoWatched, setIsVideoWatched] = React.useState<boolean>(false);
  const [submitBtnName, setSubmitBtnName] = React.useState<string>(t('taskdetaitls.educational.quest_submit_btn'));
  const [answerData, setAnswerData] = React.useState<
    Array<{
      task_id: string;
      question_id: string;
      answer: string;
      no_of_attempts: string;
      is_correct: boolean;
    }>
  >([]);
  const authContext = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const encryptedTaskId = window.btoa(questData.data.task_id.toString());

  const steps =
    questData?.data?.subtasks?.map((subtask: any) => ({
      qno: subtask?.question_no,
      qid: subtask?.question_id,
      points: subtask?.points,
      quest: subtask?.question,
      opts: subtask?.options.map((option: any) => option.option_value),
      ans: subtask?.options.find((option: any) => option.correct_option === "1")
        ?.option_value,
      hint: subtask?.hint?.content_type,
    })) || [];

  const stepperRef = React.useRef<StepperContainerHandle>(null);
  Object.preventExtensions(stepperRef);
  const validateAnswer = (selectedValue: any, correctAnswer: any,attempts:any) => {
    if (stepperRef.current) {
      return stepperRef.current.validateAns(selectedValue, correctAnswer,attempts);
    }
    return null;
  };
  const isStepOptional = (step: number) => {
    return step === 4;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async (ansData: any) => {
    await storeAnswers(ansData).then((res)=>{
      setCorrectAnswer(null);
      setLoading(false);
      if (activeStep+1 !== steps.length) {
        setAttempts(0);
        setSubmitBtnName(t('taskdetaitls.educational.quest_submit_btn'));
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
  
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        navigate(`/points/${encryptedTaskId}`);
      }
      setIsDirty(false);
    }).catch((err)=>{
      setLoading(false);
    });
  };

  // const handleBack = (activeStep: number) => {
  //   setCorrectAnswer(null);

  //   if (activeStep !== 0) {
  //     setShowConfirmation(false);
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //     setIsDirty(false);
  //   } else {
  //     setShowConfirmation(true);
  //   }
  // };

  const storeAnswers = async (answerData: any) => {
    setLoading(true);
    return new Promise(async(resolve,reject)=>{
      if (answerData.length > 0 && answerData.length === steps.length) {
        if (authContext) {
          const { token } = authContext;
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/answer/store-answer`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                'Accept-Language':i18n.language,
                },
                body: JSON.stringify(answerData),
              }
            );
  
            if (!response.ok) {
              throw new Error("Failed to store answers");
            }
            setLoading(false);
  
            const result = await response.json();
            resolve(result)
            console.log("Answers stored successfully:", result);
          } catch (error) {
            setLoading(false);
            console.error("Error submitting answer", error);
            reject("Error submitting answer");
          }
        }else{
          setLoading(false);
          reject("Error submitting answer");
        }
      }
      resolve("not needed")
    })
  };
  const submitHandler = async (data: any) => {
    setLoading(true);
    const correctanswer = steps.filter(
      (va: any) => va.qno === activeStep + 1
    )[0].ans;
    console.log("vv", data);

    const requestBody = {
      question_id: steps[activeStep]?.qid,
      option_selected: data.answer,
      no_of_attempts: attempts + 1,
    };
    if (authContext) {
      const { token } = authContext;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/answer/verify-answer`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
              'Accept-Language':i18n.language,
            },
            body: JSON.stringify(requestBody),
          }
        );

        const result = await response.json();
        if (result.success) {
          setLoading(false); //

          const datas = result.data?.is_correct;
          const correctAnsRes = result.data?.currectAnswer;
          const no_of_attempts = result.data?.no_of_attempts;
          // this will rerender radio icons
          validateAnswer(data.answer, correctanswer,no_of_attempts);

          if (datas) {
            setAnswerData((prev) => {
              const updatedData = [
                ...prev,
                {
                  task_id: questData.data.task_id,
                  question_id: steps[activeStep]?.qid,
                  answer: data.answer,
                  no_of_attempts: no_of_attempts,
                  is_correct: datas,
                },
              ];
              return updatedData;
            });
            // alert(activeStep+"---->"+(steps.length-1));
            (activeStep!==steps.length-1)? setSubmitBtnName(t('taskdetaitls.educational.next_btn')):setSubmitBtnName(t('taskdetaitls.educational.result_btn'))
          }else if (!datas && no_of_attempts < TOTALATTEMPTS) {
            setSubmitBtnName(t('taskdetaitls.educational.try_again_btn'))
          }
          if (!datas && no_of_attempts === TOTALATTEMPTS) {
            setAnswerData((prev) => {
              const updatedData = [
                ...prev,
                {
                  task_id: questData.data.task_id,
                  question_id: steps[activeStep]?.qid,
                  answer: data.answer,
                  no_of_attempts: no_of_attempts,
                  is_correct: datas,
                },
              ];
              if (correctAnswer === null) {
                setCorrectAnswer({
                  quest: steps[activeStep].quest,
                  ans: correctAnsRes,
                });
                setIsStepFailed((prev) => [...prev, activeStep - 1]);
              }
              return updatedData;
            });
          }
          console.log("result", result);
        } else {
          console.error("Failed to process the answer", result.message);
          setLoading(false); //
        }
      } catch (error) {
        console.error("Error submitting answer", error);
        setLoading(false); //
      }
    }
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      console.log("Form fields changed:", value);
      setIsDirty(true);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
  };

  return (
    <Box sx={{ width: "100%", 
    // height: !startquiz ? '450px' : '500px', overflowY: "auto"
     }}>
      {/* <div className='head'>
        <Button onClick={!startquiz ? () => navigate('/tasks') : () => handleBack(activeStep)}><img src={back} /></Button>
        <Typography variant="body1">{i18n.language==="hi"?questData?.data?.task_desc_hi:questData?.data?.task_desc_en}</Typography>
      </div> */}
      {!startquiz ? (
        <>
          {" "}
          <VideoComponent onVideoEnd={handleVideoEnd} videoUrl={questData?.data?.introdution_video} posterUrl={questData?.data?.intro_poster}/>
          <Box className="Video">
            <Box 
            // sx={{ minHeight: 'calc(100vh - 500px)' }}
             className="foot">
              <Button
                disabled={!isVideoWatched}
                onClick={() => setStartquiz(true)}
                className="btn_red"
              >
                {t('taskdetaitls.educational.start_btn')}
              </Button>
              {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
            </Box>
          </Box>
          <NoteCoin
            note={t('taskdetaitls.educational.note')}
            note_label={t('taskdetaitls.educational.note_label')}
            home="home"
            iconHeight={130}
          />
        </>
      ) : (
        <>
          <StyledStepper
            connector={<QontoConnector />}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map(({ qno, points }: any, index: any) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
                icon?: any;
                color?: string;
              } = {
                color: "#C8172F",
              };
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepFailed.includes(index - 1)) {
                labelProps.color = "error";
                labelProps.error = true;
                labelProps.icon = (
                  <StepIcon
                    style={{ color: "red" }}
                    icon={<Cancel color="error" />}
                  />
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <StyledStep
                  error={isStepFailed.includes(index - 1)}
                  key={qno}
                  {...stepProps}
                >
                  <StyledStepLabel {...labelProps}>
                    <span
                      className={`${
                        isStepFailed.includes(index - 1) && "error"
                      }`}
                    >{`Question ${qno}`}</span>
                    <span
                      className={`points_count ${
                        isStepFailed.includes(index - 1) && "error"
                      }`}
                    >{`${points}pts`}</span>
                  </StyledStepLabel>
                </StyledStep>
              );
            })}
          </StyledStepper>
          <React.Fragment>
            {/* content */}
            <Box className="steps">
              <form
                className="stepperForm"
                // onSubmit={handleSubmit((data) => submitHandler(data))}
              >
                <img className="img-fluid" crossOrigin="anonymous" src={questData?.data?.intro_poster} alt="back img" />

                <StepperContainer
                  hint={steps[activeStep]?.hint}
                  ref={stepperRef}
                  isStepFailed={isStepFailed.includes(activeStep - 1)}
                  setCorrectAnswer={setCorrectAnswer}
                  watch={watch}
                  setAttempts={setAttempts}
                  activeStep={activeStep}
                  TOTALATTEMPTS = {TOTALATTEMPTS}
                  register={register}
                  data={steps.filter((va: any) => va.qno === activeStep + 1)[0]}
                />
                <Box 
                // sx={{ minHeight: 'calc(100vh - 500px)' }}
                 className="foot">
                  <Button
                    className="btn_red"
                    disabled={loading ? isDirty : !isDirty}
                    onClick={
                    (submitBtnName===t('taskdetaitls.educational.next_btn'))?()=>handleNext(answerData):submitBtnName===t('taskdetaitls.educational.result_btn')?(()=>handleNext(answerData)):handleSubmit((data) => submitHandler(data))
                    }
                  >
                    {submitBtnName}
                    {loading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          height: "20px !important",
                          width: "20px !important",
                          marginLeft: "8px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                  {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
                </Box>
              </form>
              {(
                <Box className="chanceLimit">
                  <Typography variant="caption">{t("taskdetaitls.educational.attempts")}</Typography>
                  <Box className="count">
                    <Typography variant="body2">
                      <span>{TOTALATTEMPTS-attempts}</span>
                    </Typography>
                    {/* <img src={"/images/heart.svg"} alt="" /> */}
                    <Icons width={15} height={15} name={TOTALATTEMPTS-attempts===TOTALATTEMPTS?"heart":"bheart"} />
                  </Box>
                </Box>
              )}
              <HoldOn
                show={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                handleBacked={() => setStartquiz(false)}
              />
              {correctAnswer !== null && (
                <CorrectAnswer
                  loading={loading}
                  correctAnswer={correctAnswer}
                  setCorrectAnswer={setCorrectAnswer}
                  handleNext={() => handleNext(answerData)}
                />
              )}
            </Box>
          </React.Fragment>
        </>
      )}
    </Box>
  );
}
