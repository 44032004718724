import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import tickCircle from '../../assests/images/tick-circle.svg'
import Icons from '../Icons';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    bgcolor: '#FFF',
    borderRadius: '12px 12px 0px 0px',
    padding: '40px 20px 20px 20px',
    boxShadow: 24,
    p: 4,
};

function CorrectAnswer({correctAnswer,setCorrectAnswer,handleNext,loading}:any) {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    React.useEffect(()=>{
        if(correctAnswer!==null){
            setOpen(true)
        }else{
            setOpen(false)
            setCorrectAnswer(null)
        }
    },[correctAnswer])
    const handleOpen = () => setOpen(true);


    return (
        <div style={{zIndex:"13"}}>
            {/* <Button onClick={handleOpen}>Correct Answer</Button> */}
            <Modal className="holdOn correctAnswer"
                open={open}
                onClose={handleNext}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modalContent">
                    <IconButton className='closeBtn'
                        aria-label="close"
                        onClick={handleNext}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                    <Icons name={'home'} />
                        {/* <img src={'/images/gold_cain.svg'} alt="" /> */}
                    </Box>
                    <Typography id="modal-modal-title" variant="caption1" > The Correct answer for  </Typography>
                    <Typography id="modal-modal-description" variant='body1'>{correctAnswer?.quest} </Typography>
                    <Box className="answerBox">
                        <Typography id="modal-modal-description" variant='body1'> {correctAnswer.ans} </Typography>
                        <img src={tickCircle} alt="" />
                    </Box>
                    <Box className="modal_cta">
                        <Button onClick={handleNext} disabled={loading} className="red_btn">
                            {t('taskdetaitls.educational.result_btn')}
                            {loading ? (
                            <CircularProgress
                                sx={{
                                color: "white",
                                height: "20px !important",
                                width: "20px !important",
                                marginLeft: "8px",
                                }}
                            />
                            ) : (
                            ""
                            )}
                            </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default CorrectAnswer;
