import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getsession, getTaskDetails} from '../utils/apis';
import {
  KEY_AUTH_DETAILS,
  KEY_BILLINGCITY,
  KEY_LANG,
} from "../utils/contants";
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

export default function Auth() {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext=useContext(AuthContext)
  const { i18n } = useTranslation();

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const params:any = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    if(params["clientHeight"] && params["clientHeight"]!==null){
      document.documentElement.style.minHeight = `${params["clientHeight"]}px`;
      document.body.style.minHeight = `${params["clientHeight"]}px`;
    }
    if(params['query'] && params['query']!==null){
      // window.parent.postMessage({ action: 'secretToken', label:  params['query']}, '*');

        getsession(params['query']).then(async(result)=>{
            console.log("resultresult",result)
            const {token,data}=result
            const accId=data?.User?.Contact?.AccountId||undefined
            const contactId = data?.User?.ContactId || undefined;
            const city = data?.User?.Contact?.Account?.BillingAddress?.city || undefined;
            window.parent.postMessage({ action: 'storeToLocal', label: city }, '*');
            localStorage.setItem(KEY_BILLINGCITY, city);

          const lang = data?.User?.LanguageLocaleKey || 'en_IN';

            if(token && accId){
              authContext?.setSession(token)
                localStorage.setItem(KEY_AUTH_DETAILS,accId)
                localStorage.setItem(KEY_LANG, lang);
                i18n.changeLanguage(lang)
                await getTaskDetails(contactId).then((res2)=>{
                    if(res2.data && Array.isArray(res2.data) && res2.data.length>0){
                        const {task_id}=res2.data[0]
                        navigate(`/tasks/${window.btoa(task_id)}`)
                    }else{
                      navigate(`/tasks`)

                    }
                    console.log(res2,'resultData')
                })
            }
        }).catch((errr)=>console.log("error",errr))
    }
    console.log("parama",params['query'])
  },[location])


  return (
    <div>Authenticating....</div>
  )
}
