import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { t } from "i18next";
import clockCutted from "../../assests/images/marlboro-new.svg";
import coins from "../../assests/images/coins.svg";
import Icons from "../Icons";
import icon from "../../assests/images/step1-icon.svg";
import VideoModal from "./InstructionVideoModal";
import i18n from "../../i18n";

function Instruction({
  questData,
  sethHasReadInstruction,
}: {
  questData: any;
  sethHasReadInstruction: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const tasks =
    questData?.data?.subtasks?.map((subtask: any, index: number) => ({
      subtitle: subtask?.question,
      question_id: subtask?.question_id,
      sampleImage: `${process.env.REACT_APP_BASE_URL}${subtask?.sample_picture}`,
      task_id: subtask?.task_id,
      reward:
        questData?.data.bonus_point > 0
          ? `${t("earn_pnts.points_earn_diwali", {
              points: subtask.points || 0,
              bonus: questData?.data.bonus_point || 0,
            })}`
          : `${t("earn_pnts.points_earn", { points: subtask.points || 0 })}`,
      // reward: `${t('earn_pnts.points_earn', { points: subtask.points || 0 })}`,
      steps:
        subtask?.hint?.steps?.map((step: any) => ({
          step: `${t("taskdetaitls.dot_code_scanning.step_label")} ${
            step.step_number
          }`,
          description: step.step_text,
          icon: step.step_icon || null,
        })) || [],
      sample_picture: subtask?.hint?.sample_image,
      // takeAPic: subtask?.options[0].option_value
    })) || [];

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Box
        sx={{
          borderRadius: "16px",
          border: "1px solid rgba(250, 212, 102, 0.00)",
          background:
            "linear-gradient(110deg, rgba(255, 248, 187, 0.00) 38.52%, rgba(220, 220, 106, 0.32) 100%), #FFF",
          paddingLeft: "16px",
          margin: "15px 10px 0 15px",
          textAlign: "left",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center"
        }}
      >
        <Box sx={{ padding: "15px 0" }}>
          <Typography
            variant="caption1"
            sx={{
              color: "#7D7E7F",
              letterSpacing: "0.24px",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            Task
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", letterSpacing: "0.16px" }}
          >
            {questData?.data?.task}
          </Typography>
        </Box>
        <Box sx={{ padding: "18px 0" }}>
        <img src={clockCutted} alt="clock" />
        </Box>
      </Box>
      {/* ))} */}
      {tasks
        .filter((va: any, ind: any) => ind === 0)
        .map((task: any, index: any) => (
          <Box
            key={index}
            sx={{
              background: "#FFFFFF",
              borderRadius: "12px",
              margin: "10px 10px",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                padding: "12px 16px 0px 16px",
                gap: "12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {task.steps.map((step: any, stepIndex: any) => (
                <Box
                  key={stepIndex}
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "45px",
                      height: "45px",
                      backgroundColor: "#FFEBEF",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Icons name={step.icon} /> */}
                    {step.icon !== null ? (
                      <img
                        crossOrigin="anonymous"
                        src={`${process.env.REACT_APP_BASE_URL}${step.icon}`}
                      />
                    ) : (
                      <Icons name={icon} />
                    )}
                  </Box>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      variant="caption1"
                      sx={{ color: "#7D7E7F", fontWeight: "600",display:"block" }}
                    >
                      {step.step}:
                    </Typography>
                    <Typography
                      variant="caption1"
                      sx={{ fontWeight: "600", lineHeight: "1" }}
                    >
                      {step.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {/* <Typography
                variant="body2"
                component={"a"}
                sx={{
                  fontWeight: "700",
                  letterSpacing: "0.14px",
                  padding: "16px 10px",
                  borderBottom: "1px dashed #C5C6C7",
                  marginTop: "8px",
                }}
                href={'https://dev.mitrapp2.com/wsa/static/media/dotCode_hin.d66c953b950557e6befe.mp4'}
              >
                {t("taskdetaitls.dot_code_scanning.watch_now")}
              </Typography> */}
              <VideoModal videoUrl={i18n.language==="hi"?questData?.data?.introdution_video?.replace("scaning-v1", "scaning-v1_hindi"):questData?.data?.introdution_video||'https://dev.mitrapp2.com/wsa/static/media/dotCode_hin.d66c953b950557e6befe.mp4'}/>
              <Box sx={{ position: "relative", padding: "12px 16px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "16px",
                    background: "#F5F6F7",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                        borderRadius: "16px",
                    }}
                    crossOrigin="anonymous"
                    src={task.sampleImage}
                    alt="Default"
                    width="100%"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}

      <Box className="foot">
        <Button
          type="submit"
          className="btn_red"
          onClick={() => sethHasReadInstruction(true)}
        >
          {t("taskdetaitls.dot_code_scanning.submit")}
        </Button>
      </Box>
    </Box>
  );
}

export default Instruction;
