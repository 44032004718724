import React from 'react';
import BrandSick from '../Brand/BrandSick';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import WhitePack from '../../src/assests/images/whitePack.svg';
// import BluePack from '../../src/assests/images/bluePack.svg';
import TagOrange from '../../src/assests/images/taggzOrange.svg';
import MarBol from '../../src/assests/images/morboCardImg.svg';
import WhitePack2 from '../../src/assests/images/whitePack2.svg';
import WhitePack3 from '../../src/assests/images/whitePack3.svg';
import WhitePack4 from '../../src/assests/images/whitePack4.svg';
import WhitePack5 from '../../src/assests/images/WhitePack5.svg';
import WhitePack6 from '../../src/assests/images/WhitePack6.svg';
import WhitePack7 from '../../src/assests/images/WhitePack7.svg';
// import WhitePack8 from '../../src/assests/images/WhitePack8.svg';
import WhitePack88 from '../../src/assests/images/whitePack88.svg';
import WhitePack9 from '../../src/assests/images/WhitePack9.svg';
import WhitePack10 from '../../src/assests/images/WhitePack10.svg';
import WhitePack11 from '../../src/assests/images/WhitePack11.svg';
import WhitePack12 from '../../src/assests/images/WhitePack12.svg';
import WhitePack13 from '../../src/assests/images/WhitePack13.svg';
import WhitePack14 from '../../src/assests/images/WhitePack14.svg';
import WhitePack15 from '../../src/assests/images/WhitePack15.svg';
import WhitePack16 from '../../src/assests/images/WhitePack16.svg';




const productData = [
    {
        imgSrc: MarBol,
        alt: 'White Pack',
        titles: ['Marlboro Red', '10s pack : ₹170.00', '20s pack : ₹340.00'],
    },
    {
        imgSrc: WhitePack2,
        alt: 'Blue Pack',
        titles: ['Marlboro Gold', '10s pack : ₹170.00', '20s pack : ₹340.00'],
    },
    {
        imgSrc: WhitePack3,
        alt: 'White Pack',

        titles: ['Marlboro Gold Advance', '10s pack : ₹170.00', '20s pack : ₹340.00'],
    },
    {
        imgSrc: WhitePack4,
        alt: 'White Pack',
        titles: ['Marlboro Fuse Beyond', '10s pack : ₹170.00', '20s pack : ₹340.00'],
    },
    {
        imgSrc: WhitePack5,
        alt: 'White Pack',
        titles: ['Marlboro Vista Forest Fusion', '20s pack : ₹340'],
    },
    {
        imgSrc: WhitePack6,
        alt: 'White Pack',
        titles: ['Marlboro Clove Mix', '10s pack : ₹170'],
    },
    {
        imgSrc: WhitePack7,
        alt: 'White Pack',
        titles: ['Marlboro Vista Tropical Fusion', '10s pack : ₹170'],
    },
    {
        imgSrc: WhitePack88,
        alt: 'White Pack',
        newTag: 'New',
        titles: ['Marlboro Vista Double Fusion', '20s pack : ₹300'],
    },
    {
        imgSrc: WhitePack9,
        alt: 'White Pack',
        titles: ['Marlboro Fine Touch', '10s pack : ₹120'],
    },
    {
        imgSrc: WhitePack10,
        alt: 'White Pack',
        titles: ['Marlboro Filter Black', '12s pack : ₹150'],
    },
    {
        imgSrc: WhitePack11,
        alt: 'White Pack',
        titles: ['Marlboro Advance Compact', '10s pack : ₹95'],
    },
    // {
    //     imgSrc: WhitePack12,
    //     alt: 'White Pack',
    //     titles: ['Marlboro Vista Blue Ice', '10s pack : ₹80'],
    // },
    // {
    //     imgSrc: WhitePack13,
    //     alt: 'White Pack',
    //     titles: ['Marlboro Vista Forest Twist', '10s pack : ₹80'],
    // },
    {
        imgSrc: WhitePack14,
        alt: 'White Pack',
        newTag: 'New',
        titles: ['Marlboro Vista Taaza Twist', '10s pack : ₹95'],
    },
    {
        imgSrc: WhitePack15,
        alt: 'White Pack',
        titles: ['Marlboro Advance Pocket', '10s pack : ₹60'],
    },
    {
        imgSrc: WhitePack16,
        alt: 'White Pack',
        titles: ['Marlboro Filter Black Kings', '10s pack : ₹150'],
    },

];

const Brand = () => {
    const navigate = useNavigate();

    return (
        <>
            <BrandSick />
            <Box sx={{ padding: "0 20px 20px", cursor: "pointer" }} >
                <Grid container spacing={2}>
                    {productData.map((product, index) => (
                        <Grid item md={6} xs={6} key={index}>
                            <CardContent sx={{ background: "#FFFFFF", boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.05)", borderRadius: "12.5px", padding: "0", height: "100%" }}>
                                <Box sx={{ position: "relative" }}>
                                    <img
                                        src={product.imgSrc}
                                        alt={product.alt}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderTopLeftRadius: "8px",
                                            borderTopRightRadius: "8px",
                                            maxWidth: "335px"
                                        }}
                                    />
                                    {product.newTag && (
                                        <Box sx={{ position: "absolute", top: "17px", right: "-3px" }}>
                                            <Box sx={{ position: "relative" }}>
                                                <img
                                                    src={TagOrange}
                                                    alt={'New'}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                />
                                                <Typography sx={{ position: 'absolute', top: "47%", fontSize: "12px", fontWeight: "500", lineHeight: "120%", letterSpacing: "-0.6px", color: "#FFFFFF", transform: "translate(-50%, -50%)", left: "50%" }}>
                                                    {product.newTag}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {product.titles.map((title, i) => (
                                    <Typography
                                        key={i}
                                        sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "20px", color: "#282829", marginBottom: "4px", padding: "0 10px" }}
                                    >
                                        {title}
                                    </Typography>
                                ))}
                            </CardContent>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default Brand;
