import {
  Home,
  QAIcon,
  QAIcon2,
  Amazon,
  Dollar,
  Verify,
  heart,
  bheart,
  step1,
  step2,
  step3,
  SadCoin,
  happycoin,
  sadgoldcoin,
  educational,
  availability,
  folderImage,
  timerImage,
  goldCoin,
  starGroup,
  coinCutted,
  roundLock,
  amazonIcon,
  myntraIcon,
  boxTick,
  headPhone,
  leftArrowIcon,
  coin_Mascot_diwali,
  coinsSvgicon,
  errorIcon,
  brokenCoin,
  ticketStar,
  closeIcon,
  fileDicon,
  coinDicon,
  penDicon,
  starIcon1,
  arrowDown
} from "../../utils/icons";
type IconComponentProps = {
  name: string;
  fill?: string;
  width?: number;
  height?: number;
  style?: object;
  onClick?: () => void;
};
type IconTypes = { [name: string]: ReactSVGComponent };

const iconTypes: IconTypes = {
  home: Home,
  qaIcon: QAIcon,
  qaIcon2: QAIcon2,
  amazon: Amazon,
  dollar: Dollar,
  verify: Verify,
  heart: heart,
  bheart: bheart,
  step1: step1,
  step2: step2,
  step3: step3,
  sadCoin: SadCoin,
  happyCoin: happycoin,
  sadcoin: sadgoldcoin,
  educational: educational,
  availability: availability,
  folderImage: folderImage,
  timerImage: timerImage,
  goldCoin: goldCoin,
  starGroup: starGroup,
  coinCutted: coinCutted,
  roundLock: roundLock,
  amazonIcon: amazonIcon,
  myntraIcon: myntraIcon,
  boxTick: boxTick,
  headPhone: headPhone,
  leftArrowIcon: leftArrowIcon,
  coin_Mascot_diwali: coin_Mascot_diwali,
  coinsSvgicon: coinsSvgicon,
  errorIcon: errorIcon,
  brokenCoin: brokenCoin,
  ticketStar: ticketStar,
  closeIcon: closeIcon,
  fileDicon: fileDicon,
  coinDicon: coinDicon,
  penDicon: penDicon,
  starIcon1: starIcon1,
  arrowDown: arrowDown,
};

const Icons = ({ name, ...props }: IconComponentProps) => {
  const Icons = iconTypes[name];
  return <Icons {...props} />;
};

export default Icons;