import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.scss'
import VoucherCards from './VoucherCards';
import { t } from 'i18next';

const RewordsAndVouComponent = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ padding: "32px 20px", background: "linear-gradient(141deg, #282829 -0.06%, #620B17 97.43%);" }}>
            <p className='title'>{t("rewards.title")} </p>
            <p className='sub_title'>{t("rewards.sub_title")}</p>
            <VoucherCards />
            <Button sx={{
                background: "#C8172F", width: "100%", borderRadius: "8px", color: "#FFF", fontSize: "14px", lineHeight: "20px", fontWeight: "500", textTransform: "none",
                ":hover": { color: "#fff" }
            }}
                onClick={() => navigate('#')}
            >
                {t("rewards.view_all_reward")}
            </Button>

        </Box>
    );
};

export default RewordsAndVouComponent;
