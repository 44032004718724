import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
} from "@mui/icons-material";
import React, { forwardRef, Ref, useContext, useEffect, useImperativeHandle, useState } from "react";
import "./stepper.scss";
import { AlertContext } from "../../context/AlertContext";
import { t } from "i18next";
// import { TOTALATTEMPTS } from "../../utils/contants";

interface StepperContainerProps {
  data: any;
  register: any;
  isStepFailed: boolean;
  watch: any;
  activeStep: any;
  TOTALATTEMPTS: any;
  setAttempts: React.Dispatch<React.SetStateAction<any>>;
  setCorrectAnswer: React.Dispatch<React.SetStateAction<any>>;
  hint: any;
}

export interface StepperContainerHandle {
  validateAns: (selectedValue: any, correctAnswer: any, attempts: any) => string;
}

const StepperContainer = forwardRef<StepperContainerHandle, StepperContainerProps>(
  (props, ref) => {
    const {
      register,
      activeStep,
      isStepFailed,
      setAttempts,
      TOTALATTEMPTS,
      hint,
    } = props;
    const validateAns = (selectedValue: any, correctAnswer: any, attempts: any) => {
      if (selectedValue !== null) {
        setSelectedValue(selectedValue)
        if (selectedValue === correctAnswer) {
          setStatus("correct");
          setShowHint(false);
          return "correct"
        } else {
          setStatus("wrong");
          showAlert(t("alertMessage"), "error");
          setShowHint(true);
          // to hide the alert
          if (attempts === TOTALATTEMPTS) {
            setShowHint(false)
          }
          if (attempts !== TOTALATTEMPTS) {
            setAttempts((prev: any) => prev + 1);
          }
          return "wrong"
        }
      }
      return "wrong"


    }

    useImperativeHandle(ref, () => ({ validateAns }));

    const { quest = "", opts = [] } = props.data || {};
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const { showAlert } = useContext(AlertContext);

    const [status, setStatus] = useState<string | null>(null); // null, 'correct', 'wrong'
    const [showHint, setShowHint] = useState(false);
    useEffect(() => {
      setStatus(null);
      setShowHint(false)
    }, [activeStep])
    return quest ? (
      <>
        <Box className="custonRadio">
          <FormControl component="fieldset">
            <FormLabel component="legend">{quest}</FormLabel>
            {showHint && (
              <Box className="hint">
                <img src={"/images/hint.svg"} alt="" />
                <Typography variant="body2">Hint: {hint} </Typography>
              </Box>
            )}
            <RadioGroup aria-label="quiz">
              {Array.isArray(opts) &&
                opts.map((va, index) => (
                  <FormControlLabel
                    disabled={isStepFailed || status === "correct"}
                    key={index}
                    sx={
                      (status === "correct" && va === selectedValue)
                        ? { background: "#EBF7E6 !important", border: '1px solid #3BA755 !important' }
                        : (status === "wrong" && va === selectedValue)
                          ? { background: "#FFEBEF !important", border: '1px solid #F14B56 !important' }
                          : { background: '#FFFFF !important' }
                    }
                    value={va}
                    control={
                      <Radio
                        sx={{ '&.Mui-checked': { color: status === "correct" ? '#2E844A !important' : '#C8172F !important', } }}
                        icon={
                          status === "correct" && va === selectedValue ? (
                            <CheckCircleIcon />
                          ) : status === "wrong" && va === selectedValue ? (
                            <CancelIcon />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )
                        }
                        checkedIcon={
                          status === "correct" && va === selectedValue ? (
                            <CheckCircleIcon />
                          ) : status === "wrong" && va === selectedValue ? (
                            <CancelIcon />
                          ) : (
                            <RadioButtonCheckedIcon />
                          )
                        }
                        {...register("answer", {
                          onChange: (e: any) => {
                            setShowHint(false)
                          }
                        })}
                      />
                    }
                    labelPlacement="start"
                    label={va}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </>
    ) : null;
  })

export default StepperContainer;
