import React from 'react'
import Notification from '../components/natifications/Index'
import { Box } from '@mui/material'

export default function Notifications() {
  return (
    <div>
        <Notification />
        <Box className="foot">
          {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
        </Box>
    </div>
  )
}
