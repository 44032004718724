import React from 'react'
import Task from '../components/tasks/Task'

function Tasks() {
  return (
    <div>
        <Task />
    </div>
  )
}

export default Tasks