import React, { createContext, ReactNode, useState } from 'react';

interface AlertState {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  }
interface AlertContextType {
    alert: AlertState;
    showAlert: (message: string, severity?: 'success' | 'info' | 'warning' | 'error') => void;
    closeAlert: () => void;
  }
// Create a default context value
const defaultAlertContext: AlertContextType = {
    alert: { open: false, message: '', severity: 'success' },
    showAlert: () => {},
    closeAlert: () => {},
  };
  
  // Create the context
  export const AlertContext = createContext<AlertContextType>(defaultAlertContext);

  // Create the provider component
  export const AlertProvider = ({ children }: { children: ReactNode }) => {
    const [alert, setAlert] = useState<AlertState>({ open: false, message: '', severity: 'success' });
  
    const showAlert = (message: string, severity: 'success' | 'info' | 'warning' | 'error' = 'success') => {
        console.log(message)
      setAlert({ open: true, message, severity });
    };
  
    const closeAlert = () => {
      setAlert({ ...alert, open: false });
    };
  
    return (
      <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
        {children}
      </AlertContext.Provider>
    );
  };