import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en_IN/translation.json';
import hiTranslation from './locales/hi/translation.json';
import { KEY_LANG } from './utils/contants';

// Initialize i18next
const lang=localStorage.getItem(KEY_LANG)||'en_IN';
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
    },
    lng: lang, // Default language
    fallbackLng: 'en_IN', // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
