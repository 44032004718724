import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Divider, Icon, styled, Typography } from "@mui/material";
import "./tasks.scss";
import upcomingTask from "../../assests/images/upcoming-task.svg";
import activeImg from "../../assests/images/active-img.svg";
import verifyImg from "../../assests/images/verify.svg";
import clock from "../../assests/images/clock.svg";
import coins from "../../assests/images/coins.svg";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getTaskList } from "../../utils/apis";
import { AuthContext } from "../../context/AuthContext";
import Icons from "../Icons";
import { happycoin } from "../../utils/icons";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const StyledTabs = styled(Tab)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 500,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    fontWeight: '700 !important',
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: '#C8172F',
  borderRadius: '8px',
  padding: '0px 12px 0px 12px',
  '&.Mui-disabled': {
    backgroundColor: '#7D7E7F',
    color: '#C5C6C7',
    cursor: 'not-allowed',
    opacity: 0.6,
  },
}));


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Task() {
  const { t } = useTranslation();
  // const [isLoading, setIsLoading] = useState(false);


  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState({
    completed_tasks: [],
    active_tasks: [],
    upcoming_tasks: [],
  });
  const authContext = useContext(AuthContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  useEffect(() => {
    const fetchData = async () => {
      if (authContext) {
        const { token }: any = authContext;
        try {
          const data = await getTaskList(token);
          if (data && typeof data === "object") {
            setTaskList({
              completed_tasks: data.completed_tasks.tasks || [],
              active_tasks: data.active_tasks.tasks || [],
              upcoming_tasks: data.underreview_tasks.tasks || [],
            });
          } else {
            console.error("Data format is incorrect:", data);
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };
    fetchData();
  }, [authContext]);

  console.log("taskList", taskList);

  const getdynmicurl=async(task_id:any,publish_id:any)=>{
    const formdata = new FormData();

    const requestOptions:any = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
    
   return await fetch("https://api.dev.mitrapp2.com/wsa/api/auth/generate-token?secret_key=s3cr3tK3y", requestOptions)
      .then((response) => response.json())
      .then((result:any) => {
        console.log(result,result.auth_token,"result token")
        if(result){
          const url = `https://dev.mitrapp2.com/wsa/app?languageId=${i18n.language.includes('hi')?2:1}&token=${result.auth_token}&published_id=${publish_id}`;
          // alert(url)
          return url
        }else{
          throw new Error("Failed to open task")
        }
        throw new Error("Failed to open task")
      
      })
      .catch((error) => {
        console.error(error)
        throw new Error("Failed to open task")

      });
  }
  const handleClick = async (task:any) => {
    // setIsLoading(true);

    try {
      if (task.task_type === t(`taskdetaitls.dot_code_scanning.type`)) {
        const dynamicUrl:any = await getdynmicurl(task.task_id, task.id);
        // alert(dynamicUrl)
        navigate(`/tasks/${window.btoa(task.task_id)}`);

        // window.parent.postMessage({ action: 'redirectToDotCode', label:  dynamicUrl}, '*');
        // window.open(dynamicUrl, '_blank'); // Open in a new tab
      } else {
        navigate(`/tasks/${window.btoa(task.task_id)}`);
      }
    } finally {
      // setIsLoading(false);
    }
  };
  const renderTask = (task: any, task_type: string) => {
    const getTimeLeft = (endDate: any,current_date:any) => {
      const end = new Date(endDate);
      const today = new Date(current_date);
      const timeDiff = end.getTime() - today.getTime();
 
      const daysLeft = Math.floor(timeDiff / (1000 * 3600 * 24));
      const hoursLeft = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
    
      if (daysLeft > 0) {
        return `${daysLeft} ${daysLeft > 1 ? t('days_lefts') : t('day_left')}`;
      } else if (hoursLeft > 0) {
        return `${hoursLeft} ${hoursLeft > 1 ? t('hours_left') : t('hour_left')}`;
      } else {
        return t('task_names.expired');
      }
    }
    
    return (
      <Box
        key={task.task_id}
        sx={{
          borderRadius: "16px",
          background: "#FFF",
          padding: "12px",
          gap: "12px",
          marginBottom: "16px",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "12px", width: "250px" }}>
            <Box
              sx={{
                borderRadius: "9.6px",
                background: "rgba(255, 235, 239, 0.50)",
                width: "100%",
                maxWidth: "48px",
                height: "48px",
                padding: "8px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {task_type === 'completed_tasks' ? <img src={verifyImg} alt="" /> :(<img src={`${process.env.REACT_APP_S3_STATIC_URL}${task.task_icon}`} alt="" />)
                // task.task_type === t("taskdetaitls.availability.type") ?
                //   <Icons name="availability" /> :
                //   <Icons name="educational" />
                }

            </Box>
            <Box>
              {task_type === 'active_tasks' && <Typography
                variant="caption1"
                sx={{ display: "block", color: "#696A6B" }}
              >
                {/* Task */}
                {t(`task`)}
              </Typography>}
              <Typography
                variant="caption1"
                sx={{ color: "#282829", fontWeight: "600" }}
              >
                {/* {task.bonus_point>0?task.task_title:task.task} */}
                {task.task_title}
              </Typography>
              {/* {task_type === 'upcoming_tasks' && <Typography
                variant="caption1"
                sx={{ display: "block", color: "#696A6B" }}
              >
                You will earn some Points
              </Typography>} */}
              {task_type === 'completed_tasks' &&
                <Typography
                  variant="caption1Medium"
                  sx={{ display: "flex", color: "#494A4B" }}
                >
                  <Icons style={{ marginRight: '4px' }} name={task.credit_points>0?"happyCoin":"sadcoin"} />{" "}
                  {`${task.credit_points} ${t(`earn_pnts.points_cridited`)}`}
                </Typography>}
            </Box>
          </Box>
          {task_type === "active_tasks" && <Box
            sx={{
              borderRadius: "20px",
              background: "#F5F6F7",
              display: "flex",
              padding: "2px 10px",
              gap: "2px",
              whiteSpace: "nowrap",
            }}
          >
            <img src={clock} alt="" />
            <Typography
              variant="caption1"
              sx={{ display: "block", color: "#282829", fontWeight: "600" }}
            >
              {getTimeLeft(task.task_end_date,task.current_time)}
            </Typography>
          </Box>}
        </Box>
        {task_type !== "completed_tasks" &&
          <>
            <Divider variant="fullWidth" sx={{ borderBottomStyle: "dashed", paddingBottom: "12px", marginBottom: "12px" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "24px",
                    height: "24px",
                    padding: "3px 6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                    borderRadius: "30px",
                    border: "0.75px solid #FFF2CA",
                  }}
                >
                  <Icons name={'happyCoin'} />
                </Box>
                <Typography
                  variant="caption1"
                  sx={{
                    color: "#282829",
                    fontWeight: "600",
                    alignSelf: "center",
                  }}
                >
                  {/* Earn {task.credit_points || 0} pts */}
                  {/* {task.bonus_point > 0 ?t("earn_pnts.points_earned_diwali"):t("diwali_points.points_earned")} */}
                  {task.bonus_point > 0 ?`${t('earn_pnts.points_earned_diwali', { points: task.credit_points || 0,bonus:task.bonus_point || 0 })}`:`${t('earn_pnts.points_earned', { points: task.credit_points||0})}`}
                  {/* {task.credit_points || 0}{" "}{t(`earn_pnts.points_earned`} */}
                {/* {`${t("diwali_points.points_earned")}`}: {task.credit_points} */}
                {/* {task.bonus_point > 0 && (
                  <> + {task.bonus_point} pts </>
                )} */}
                </Typography>
              </Box>
              <StyledButton
                disabled={task_type === "upcoming_tasks" || getTimeLeft(task.task_end_date,task.current_time)?.trim() === t(`task_names.expired`)}
                onClick={()=>navigate(`/tasks/${window.btoa(task.task_id)}`)}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  color: "#fff !important",
                  display: "flex",
                  height: "32px",
                  padding: "0px 12px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#C8172F",
                  width: "auto !Important",
                }}
              >
                 {/* {isLoading ? t("task_names.loading") : (task_type === "upcoming_tasks" ? t("task_names.restart_the_task") : t("task_names.start_the_task"))} */}
                {task_type === "upcoming_tasks" ? t("task_names.restart_the_task") : t("task_names.start_the_task")}
              </StyledButton>
            </Box>
          </>
        }
      </Box>
    );
  };

  const { completed_tasks, active_tasks, upcoming_tasks } = taskList;

  return (
    <section>
      <Box sx={{ width: "100%" }} className="taskTabs">
        <Box sx={{ backgroundColor: "#FFF" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <StyledTabs label={`${t("tasks.active_tab")} (${active_tasks.length})`} {...a11yProps(0)} />
            <StyledTabs
              label={`${t("tasks.inreview_tab")} (${upcoming_tasks.length})`}
              {...a11yProps(1)}
            />
            <StyledTabs
              label={`${t("tasks.completed_tab")} (${completed_tasks.length})`}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {active_tasks.map((task: any) => renderTask(task, 'active_tasks'))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {upcoming_tasks.map((task: any) => renderTask(task, 'upcoming_tasks'))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {completed_tasks.map((task: any) => renderTask(task, 'completed_tasks'))}
        </CustomTabPanel>
      </Box>
    </section>
  );
}
