import React, { useContext, useEffect, useState } from "react";
import "../components/points/Points.scss";
import { Box, Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import PriceCard from "../components/points/Voucher";
import Pnts from "../components/points/Pnts";
import { getResults } from "../utils/apis";
import { AuthContext } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VoucherSlick from "../components/vouchers-slick";
import { KEY_BILLINGCITY } from "../utils/contants";
interface voucherProps {
  BtnReq?: boolean
}


const Voucher: React.FC<voucherProps> = () => {
  const { t } = useTranslation();
  const [BtnReq, setBtnReq] = useState<boolean>(false);
  const [pointsData, setPointsData] = useState<any>(null);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const decryptedId = id ? atob(id) : "";
  const billing_city=localStorage.getItem(KEY_BILLINGCITY)|| null;

  useEffect(() => {
    const fetchData = async () => {
      if (authContext) {
        const { token }: any = authContext;
        try {
          const data = await getResults(decryptedId, token);
          setPointsData(data);
          setBtnReq(data.result_details.req_approval)
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };
    fetchData();
  }, [authContext]);

  return (
    <Box sx={{
      width: "100%",
      //  height: '560px', overflowY: "auto"
    }}>
      <Pnts pointsData={pointsData} />
      <Box className="vouchers">
        {/* <Typography variant="body1" className="voucher_head">
          My Rewards & Vouchers
        </Typography> */}
        {/* <Box>
          <Grid container spacing={1.5}>
            {pointsData?.rewards_and_vouchers?.map(
              (voucher: any, index: any) => (
                <Grid item xs={6} key={index}>
                  <PriceCard
                    coinIcon="dollar"
                    points={`${voucher.reward_value_in_points} points`}
                    voucherIcon={voucher.reward_vendor}
                    voucherText={voucher.reward_type}
                    price={`₹${voucher.reward_value_in_rupees}`}
                    buttonText="Redeem Now"
                    buttonLink={()=>window.parent.postMessage({ action: 'redirectTo', label: 'pmi-reward-page' }, '*')}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box> */}
      </Box>
     <Box sx={{ background: "linear-gradient(141deg, #282829 -0.06%, #620B17 97.43%)", textAlign: "start", padding: "32px 20px", marginBottom: "80px" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px", letterSpacing: "-0.32px", color: "#FFF", maxWidth: "272px" }}>{t("rewards.title")} </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "20px", color: "#C5C6C7", maxWidth: "272px", marginBottom: "20px" }}>{t("rewards.sub_title")}</Typography>
        {/* <VoucherSlick /> */}
        <Button sx={{
          marginTop: "20px",
          background: "#C8172F", width: "100%", borderRadius: "8px", color: "#FFF", fontSize: "14px", lineHeight: "20px", fontWeight: "500", textTransform: "none",
          ":hover": { color: "#fff" }
        }}
          onClick={() => navigate('#')}
        >
          {t("rewards.view_all_reward")}
        </Button>
      </Box>
      <Box
        // sx={{ minHeight: 'calc(100vh - 500px)' }}
        className="foot" sx={{ display: "flex", width: "100%" }}>
        {BtnReq && (
          <Button
            className="btn_trans"
            onClick={() => window.parent.postMessage({ action: 'redirectTo', label: 'pmi-all-tasks' }, '*')}
          >
            {t('taskdetaitls.availability.result.check_status')}
          </Button>
        ) || ""}
        <Button className="btn_red" onClick={() => window.parent.postMessage({ action: 'redirectTo', label: 'pmi-home-page' }, '*')}>
          {t('taskdetaitls.educational.result.go_to_home')}
        </Button>
        {/* <p>Warning: Tobacco Causes Cancer</p> */}
      </Box>
    </Box>
  );
}

export default Voucher;
