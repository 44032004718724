import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import back from '../../assests/images/back.svg'
import icon from '../../assests/images/notification-icon.svg'
import './Notification.scss'
import { AuthContext } from '../../context/AuthContext'
import i18n from '../../i18n'
import { getsession } from '../../utils/apis'
import { useLocation } from 'react-router-dom'



function Notification() {
    const [questData, setQuestData] = useState<any>(null);
    const location = useLocation(); 
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params: any = {};
        queryParams.forEach((value, key) => {
          params[key] = value;
        });
        if (params["query"] && params["query"] !== null) {
          getsession(params["query"])
            .then(async (result) => {
              console.log("resultresult", result,);
              const { token, data } = result;
              const accId = data?.User?.Contact?.AccountId || undefined;
              const contactId = data?.User?.ContactId || undefined;
              const lang = data?.User?.LanguageLocaleKey || undefined;
              if (token) {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/answer/fetch-notifications?type=notification`,
                {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        'Accept-Language':lang,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setQuestData(data);
            } catch (error) {
            console.error("Failed to fetch data:", error);
            }
              }
            })
            .catch((errr) => {
              console.log(errr);
            });
        }
        console.log("parama", params["query"]);
      }, [location]);
    // useEffect(() => {
    //     const fetchTaskData = async () => {
    //         if (authContext) {
    //             const { token } = authContext;
    //             try {
    //                 const response = await fetch(
    //                     `${process.env.REACT_APP_BASE_URL}/answer/fetch-notifications?type=notification`,
    //                     {
    //                         method: "get",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             Authorization: `Bearer ${token}`,
    //                             'Accept-Language':i18n.language,
    //                         },
    //                     }
    //                 );

    //                 if (!response.ok) {
    //                     throw new Error("Network response was not ok");
    //                 }
    //                 const data = await response.json();
    //                 setQuestData(data);
    //             } catch (error) {
    //                 console.error("Failed to fetch task data:", error);
    //             }
    //         }
    //     };
    //     fetchTaskData();
    // }, []);

    const formatTime = (createdAt: string) => {
        const now = new Date();
        const createdAtDate = new Date(createdAt);
        const diffInMinutes = Math.floor((now.getTime() - createdAtDate.getTime()) / 60000);

        if (diffInMinutes < 60) {
            return `${diffInMinutes}m`;
        } else if (diffInMinutes < 1440) {
            const hours = Math.floor(diffInMinutes / 60);
            return `${hours}hr${hours > 1 ? 's' : ''}`;
        } else {
            const days = Math.floor(diffInMinutes / 1440);
            return `${days}d${days > 1 ? 's' : ''}`;
        }
    };

    console.log('questData', questData)


    const notifications =
        questData?.data?.map((subtask: any) => ({
            message: subtask?.message,
            time: formatTime(subtask?.createdAt),
            link_label: subtask?.link_label||null,
            link: subtask?.link||null,
            // highlight: '“Know our products”.',
            // additionalText: 'Finish it quickly and earn 100+50pts',
            // buttonText: 'Go back to Home',
            // buttonLink: '#',
            // hasButton: true
        })) || [];

    return (
        <section className=''>
            {/* <Box className='head'>
                <Button><img src={back} /></Button>
                <Typography variant="body1">Notifications</Typography>
            </Box> */}

            {notifications.length === 0 ?
                <Typography sx={{ height: "100vh", width: "100%", justifyContent: "center", alignItems: "center", color: '#282829', display: "flex" }}>
                    No Notifications
                </Typography> : <>
                    {notifications.map((notification: any, index: any) => (
                        <Box key={index} className='notifications' sx={{ display: 'flex', gap: '12px', justifyContent: 'space-between', background: '#FFF', padding: '16px 20px', textAlign: 'left', marginBottom: '2px' }}>
                            <Box sx={{ backgroundColor: 'rgba(255, 235, 239, 0.50)', width: '100%', maxWidth: '39px', height: '39px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={icon} alt="Notification Icon" />
                            </Box>
                            <Box sx={{ maxWidth: '414px', width: '100%', }}>
                                <Typography variant='body2' sx={{ color: '#282829' }}>
                                    {notification.message} <span>{notification.highlight}</span> {notification.additionalText}
                                </Typography>
                                {notification.link_label!==null && (
                                    <Button
                                        className="btnRed"
                                        sx={{ color: '#FFF', fontSize: '12px', fontWeight: '700', lineHeight: '21px', letterSpacing: '0.12px', borderRadius: '8px', background: '#C8172F', height: '32px', padding: '1px 12px', marginTop: '8px', maxWidth: '144px', width: '100%', "&:hover": { background: '#C8172F', color: "#FFF" } }}
                                        onClick={() => notification.link}
                                    >
                                        {notification.link_label}
                                    </Button>
                                )}
                            </Box>
                            <Typography variant='caption1' sx={{ display: 'block', color: '#475569' }}>{notification.time}</Typography>
                        </Box>
                    ))}</>}


        </section>
    )
}

export default Notification