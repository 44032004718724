import React, { useEffect, useState } from 'react'
import Activity from '../components/activity'
import { AuthContext } from '../context/AuthContext';
import { Typography } from '@mui/material';
import i18n from '../i18n';

// const activities = [
//   {
//     task: "Know our products", time: "2m", htmlContent: ` <Typography variant="body2">
//             You have completed the <span>“Know our products”</span> task
//           </Typography>` },
//   {
//     task: "Complete the survey", time: "5m", htmlContent: ` <Typography variant="body2">
//             You bought a new voucher
//           </Typography>` },
//   {
//     task: "Complete the survey", time: "5m", htmlContent: ` <Typography variant="body2">
//             You bought a new voucher
//           </Typography>` },
// ];

function ActivityLog() {
  const [activityLog, setActivityLog] = useState<any>(null);
  const authContext = React.useContext(AuthContext);

  useEffect(() => {
    const fetchTaskData = async () => {
      if (authContext) {
        const { token } = authContext;
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/answer/fetch-notifications?type=activity_log`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
              'Accept-Language':i18n.language,
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setActivityLog(data);
        } catch (error) {
          console.error("Failed to fetch task data:", error);
        }
      }
    };
    fetchTaskData();
  }, []);

  const formatTime = (createdAt: string) => {
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    const diffInMinutes = Math.floor((now.getTime() - createdAtDate.getTime()) / 60000);

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInMinutes < 1440) {
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours}hr${hours > 1 ? 's' : ''}`;
    } else {
      const days = Math.floor(diffInMinutes / 1440);
      return `${days}d${days > 1 ? 's' : ''}`;
    }
  };

  const notifications =
    activityLog?.data?.map((subtask: any) => ({
      htmlContent: subtask?.message,
      time: formatTime(subtask?.createdAt),
    })) || [];

  console.log('activityLog', activityLog)

  return (
    <div>
      {notifications.length === 0 ?
        <Typography sx={{ height: "100vh", width: "100%", justifyContent: "center", alignItems: "center", color: '#282829', display: "flex" }}>
          No Activity log
        </Typography> : <Activity items={notifications} />
      }
    </div>
  )
}

export default ActivityLog
