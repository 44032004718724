import React from 'react';
import './Hometask.scss';
import { Box } from '@mui/material';
import Icons from '../Icons';

const HomeTaskCardComponent = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // height:"calc(40vh - 200px)",
                // height: "100vh",
            }}
        >
            <Box sx={{
                background: "#fff",
                width: "100%",
                borderRadius: "8px",
                padding: "12px 10px",
                flexDirection: "column",
                maxWidth: "335px",
                justifyContent: "center",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        marginBottom: "12px",
                    }}>
                        <Icons width={48} height={48} name={'folderImage'} />
                        <Box>
                            <p className='new_task'>New task</p>
                            <p className='know_our'>Know our products</p>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            padding: "4px 10px",
                            background: "#F5F6F7",
                            borderRadius: "20px",
                            marginLeft: "auto",
                        }}>
                            <Icons name={'timerImage'} />
                            <p className='days_left'>2 days left</p>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "12px", borderTop: "1px dashed #E8E8E8;" }}>
                    <p className='complete_to_earn'>Complete to earn 200 pts</p>
                    <a className='start_the_task' href="#">Start The Task</a>
                </Box>
            </Box>
        </Box>
    );
};

export default HomeTaskCardComponent;
