import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Rating,
} from "@mui/material";

function MultiSelect({ stepIndex, handleChange, step, selectedValue }: any) {

  const isChecked = selectedValue[stepIndex]?.answer.includes(step) || false;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    handleChange(newValue, stepIndex);
  };

  return (
    <FormControl
      component="fieldset"
      sx={{ display: "flex", alignItems: "left", gap: "12px" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: '2px',
          borderRadius: 2,
          border: "1px solid #e0e0e0",
          backgroundColor: "transparent",
          marginBottom: "1px",
          cursor: "pointer",
        }}

      >
        <FormControlLabel

          control={
            <Checkbox
              checked={isChecked}
              value={step}
              onChange={(e)=>handleCheckboxChange(e)}
              sx={{
                color: "#E7E8E9", // Set color for unchecked state
                "&.Mui-checked": {
                  color: "#B90022", // Set color for checked state
                },
              }}

            />
          }
          label={
            step
          }
          sx={{ margin: 0 , "& .MuiFormControlLabel-label": {
            fontSize: "14px", // Adjust the font size as needed
            color: "#333", // Optional: change label color
            fontWeight:'500',
          },}}
        />
      </Box>
    </FormControl>
  );
}

export default MultiSelect;
