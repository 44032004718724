import { Box, Typography } from '@mui/material';
import React from 'react';

const ComingSoon = () => {
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                background: 'linear-gradient(141deg, #282829 -0.06%, #620B17 97.43%)',
                color: '#FFF',
                textAlign: 'center',
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    letterSpacing: '2px',
                    animation: 'fadeIn 2s ease-in-out',
                    marginBottom: "12px",
                    lineHeight: "24px"
                }}
            >
                Coming Soon!
            </Typography>

            <Typography
                variant="h6"
                sx={{
                    fontSize: '16px',
                    fontWeight: '300',
                    mb: 4,
                    animation: 'fadeIn 4s ease-in-out',
                }}
            >
                Stay tuned! Something awesome is on its way.
            </Typography>

        </Box>
    );
};

export default ComingSoon;
