import React from 'react';
import HomeTaskCardComponent from '../components/HometaskCard';

const HomertaskCard = () => {
    return (
        <>
            <HomeTaskCardComponent />
        </>
    );
};

export default HomertaskCard;
