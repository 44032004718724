import React, { useContext, useState } from 'react';
import './AvailabilityTasks.scss';
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import back from '../../assests/images/back.svg'
import coins from '../../assests/images/coins.svg'
import icon from '../../assests/images/step1-icon.svg'
import uploadImg from '../../assests/images/uploadImg.svg'
import UploadPack2 from '../../assests/images/cig_pack_img.svg'
// import closePackImg from '../../assests/images/closePackImg.svg'
// import clock from '../../assests/images/gold_cain.svg'
import clockCutted from '../../assests/images/Coin_Mascot_Cutted.svg'
import CameraCapture from '../camera';
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import Icons from '../Icons';
import i18n from '../../i18n';
import { t } from 'i18next';

function AvailabilityTasks({ questData }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const authContext = useContext(AuthContext);
  const encryptedTaskId = window.btoa(questData.data.task_id.toString());
  console.log('AvailabilityTasks', questData)
  const [label, setlabel] = useState([t("taskdetaitls.availability.sample_img_label"),t("taskdetaitls.availability.sample_img_label")])

  const tasks = questData?.data?.subtasks?.map((subtask: any, index: number) => ({
    subtitle: subtask?.question,
    question_id: subtask?.question_id,
    sampleImage: `${process.env.REACT_APP_BASE_URL}${subtask?.sample_picture}`,
    task_id: subtask?.task_id,
    reward:  questData?.data.bonus_point > 0 ?`${t('earn_pnts.points_earn_diwali', { points: subtask.points || 0,bonus:questData?.data.bonus_point || 0 })}`:`${t('earn_pnts.points_earn', { points: subtask.points||0})}`,
    // reward: `${t('earn_pnts.points_earn', { points: subtask.points || 0 })}`,
    steps: subtask?.hint?.steps?.map((step: any) => ({
      step: `${t('taskdetaitls.availability.step_label')} ${step.step_number}`,
      description: step.step_text,
      icon: step.step_icon || null,
    })) || [],
    sample_picture: subtask?.hint?.sample_image || (index === 0 ? uploadImg : UploadPack2),
    takeAPic: subtask?.options[0].option_value
  })) || [];

  const [imagesCaptured, setImagesCaptured] = useState<boolean[]>(
    Array(tasks.length).fill(false)
  );
  const [images, setImages] = useState<Array<{
    task_id: string,
    question_id: string,
    answer: string,
    no_of_attempts: string,
    is_correct: string,
  }>>([]
  );


  const handleCapture = (index: number, captured: boolean, question_id: string) => {
    const updatedImages = [...imagesCaptured];
    const lables = [...label];
    updatedImages[index] = captured;
    lables[index] = t("taskdetaitls.availability.clicked_img_label");
    setImagesCaptured(updatedImages);
    setlabel(lables)
  };

  const handleSubmit = async () => {
    setLoading(true)
    // navigate("/points");
    console.log("imagesCaptured", images)
    if (authContext) {
      const { token } = authContext
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/answer/store-answer`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept-Language':i18n.language,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(images),
        });

        if (!response.ok) {
            setLoading(false)

          throw new Error('Failed to store answers');
        }

        const result = await response.json();
        console.log('Answers stored successfully:', result);
        navigate(`/points/${encryptedTaskId}`);
      } catch (error) {
        setLoading(false)
        console.error('Error submitting answer', error);
      }
    }
  };

  return (
    <Box sx={{ paddingBottom: '90px' }}>
      {/* <Box className='head' sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button onClick={() => navigate("/tasks")}><img src={back} /></Button>
          <Typography variant="body1">Variant pack pictures</Typography>
        </Box>
        <Box sx={{
          borderRadius: '40px',
          border: '1px solid #E8E8E8',
          background: '#F5F6F7',
          display: 'flex',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '4px'
        }}>
          <img src={coins} alt="" />
          <Typography variant='caption1' sx={{ fontWeight: '700' }}>{`Earn ${questData?.data?.total_points} pts`}</Typography>
        </Box>
      </Box> */}
      <Box sx={{
        borderRadius: '16px',
        border: '1px solid rgba(250, 212, 102, 0.00)',
        background: 'linear-gradient(110deg, rgba(255, 248, 187, 0.00) 38.52%, rgba(220, 220, 106, 0.32) 100%), #FFF',
        paddingLeft: '16px',
        margin: '16px 20px 0 20px',
        textAlign: 'left',
        display: 'flex',
        justifyContent:"space-between"
      }}>
        <Box sx={{ padding: '18px 0' }}>
          <Typography variant='caption1' sx={{ color: '#7D7E7F', letterSpacing: '0.24px', textTransform: 'uppercase', fontWeight: '600' }}>
            Task
          </Typography>
          <div style={{ fontWeight: '600', letterSpacing: '0.16px',fontSize:'14px' }} dangerouslySetInnerHTML={{ __html:i18n.language==="hi"?questData?.data?.task_desc_hi:questData?.data?.task_desc_en}}/>
        </Box>
        {/* <img src={clockCutted} alt="clock" /> */}
      </Box>
      {/* ))} */}
      {tasks.map((task: any, index: any) => (
        <Box key={index} sx={{ background: '#FFFFFF', borderRadius: '12px', margin: '20px', textAlign: 'left' }}>
          <Box className="cardHead" sx={{
            borderBottom: '1px dashed #C5C6C7',
            display: 'flex',
            padding: '16px 10px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant='body2'>
              {
              task.subtitle!==""?
              <>
              {`${t('taskdetaitls.availability.picture')} ${index + 1}`} :
              <span style={{ marginLeft: "4px" }}>{task.subtitle}</span>
              </>:
              (`${t('taskdetaitls.availability.picture')} ${index + 1}`)
              }
            </Typography>
            <Box sx={{
              borderRadius: '40px',
              border: '1px solid #E8E8E8',
              background: '#F5F6F7',
              display: 'flex',
              padding: '4px 8px',
              alignItems: 'center',
              gap: '4px'
            }}>
              <img src={coins} alt="" />
              <Typography variant='caption1' sx={{ fontWeight: '700' }}>{task.reward}</Typography>
            </Box>
          </Box>
          <Box sx={{ padding: '12px 16px 0px 16px', gap: '12px', display: 'flex', flexDirection: 'column' }}>
            {task.steps.map((step: any, stepIndex: any) => (
              <Box key={stepIndex} sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <Box sx={{
                  width: '100%',
                  maxWidth: '56px',
                  height: '56px',
                  backgroundColor: '#FFEBEF',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  {/* <Icons name={step.icon} /> */}
                  {
                    step.icon!==null?
                    (<img crossOrigin='anonymous' src={`${process.env.REACT_APP_BASE_URL}${step.icon}`} />)
                    :
                    <Icons name={icon} />
                  }
                 
                </Box>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant='caption1' sx={{ color: '#7D7E7F', fontWeight: '600' }}>{step.step}</Typography>
                  <Typography variant='body2' sx={{ fontWeight: '600', lineHeight: 'normal' }}>{step.description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Typography variant='body2' sx={{
            fontWeight: '700',
            letterSpacing: '0.14px',
            padding: '16px 10px',
            borderBottom: '1px dashed #C5C6C7',
            marginTop: '8px'
          }}>{label[index]}</Typography>
          <CameraCapture
            data={task}
            defaultImage={task.sampleImage}
            setImages={setImages}
            images={images}
            onCapture={(captured) => handleCapture(index, captured, task.question_id)}
          />
        </Box>
      ))}

      <Box className="foot">
        <Button
          type="submit"
          disabled={imagesCaptured.filter(captured => captured).length !== tasks.length || loading}
          className="btn_red"
          onClick={handleSubmit}
        >
          {/* Submit */}
          
          {t('taskdetaitls.availability.submit')}
          {loading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          height: "20px !important",
                          width: "20px !important",
                          marginLeft: "8px",
                        }}
                      />
                    ) : (
                      ""
                    )}
          </Button>
        {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
      </Box>
    </Box>
  );
}

export default AvailabilityTasks;
