// src/theme.ts

import { createTheme } from "@mui/material/styles";

const convertPxToRem = (pxValue: number) => {
  const baseFontSize = 16; // Replace this with your base font size in pixels (default is 16px)
  const remValue = pxValue / baseFontSize;
  return remValue;
};

// Function to calculate line height using percentage of font size
const calculateLineHeight = (fontSize: number, percentage: number) => {
  const lineHeight = (fontSize * percentage) / 100;
  return `${lineHeight}rem`;
};

const fontSizes = {
  h1: convertPxToRem(42),
  h2: convertPxToRem(32),
  h3: convertPxToRem(28),
  h4: convertPxToRem(24),
  h5: convertPxToRem(20),
  h6: convertPxToRem(18),
  body1: convertPxToRem(16),
  body2: convertPxToRem(14),
  caption: convertPxToRem(13),
  caption1: convertPxToRem(12),
  caption2: convertPxToRem(10),
};
// Define font weight variables
const FontWeight = {
  Bold: 700,
  Semibold: 600,
  Medium: 500,
  Regular: 400,
};
// Define your custom typography with calculated line height (using a percentage value)
const typography = {
  fontFamily: "Inter, sans-serif",
  h1: {
    fontSize: `${fontSizes.h1}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h1, 120), // 120% line height based on 2.5rem font size
  },
  h2: {
    fontSize: `${fontSizes.h2}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h2, 120), // 130% line height based on 2rem font size
  },
  h3: {
    fontSize: `${fontSizes.h3}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h3, 120), // 130% line height based on 2rem font size
  },
  h4: {
    fontSize: `${fontSizes.h4}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h4, 120), // 130% line height based on 2rem font size
  },
  h5: {
    fontSize: `${fontSizes.h5}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h5, 120), // 130% line height based on 2rem font size
  },
  h6: {
    fontSize: `${fontSizes.h6}rem`,
    fontWeight: FontWeight.Bold,
    lineHeight: calculateLineHeight(fontSizes.h5, 120), // 130% line height based on 2rem font size
  },
  body1: {
    fontSize: `${fontSizes.body1}rem`,
    lineHeight: calculateLineHeight(fontSizes.body1, 160),
    fontWeight: 400, // Regular
  },
  body1Medium: {
    fontSize: `${fontSizes.body1}rem`,
    lineHeight: calculateLineHeight(fontSizes.body1, 160),
    fontWeight: 500, // Medium
  },
  body2: {
    fontSize: `${fontSizes.body2}rem`,
    lineHeight: calculateLineHeight(fontSizes.body2, 160),
    fontWeight: 400, // Regular
  },
  body2Medium: {
    fontSize: `${fontSizes.body2}rem`,
    lineHeight: calculateLineHeight(fontSizes.body2, 160),
    fontWeight: 700, // Medium
  },
  caption: {
    fontSize: `${fontSizes.caption}rem`,
    lineHeight: calculateLineHeight(fontSizes.caption, 160),
    fontWeight: 500, // Medium
  },
  caption1: {
    fontSize: `${fontSizes.caption1}rem`,
    lineHeight: calculateLineHeight(fontSizes.caption1, 160),
    fontWeight: 400, // Regular
  },
  caption1Medium: {
    fontSize: `${fontSizes.caption1}rem`,
    lineHeight: calculateLineHeight(fontSizes.caption1, 160),
    fontWeight: 500, // Regular
  },
  caption2: {
    fontSize: `${fontSizes.caption2}rem`,
    lineHeight: calculateLineHeight(fontSizes.caption2, 160),
    fontWeight: 400, // Regular
  },
  // Add more typography variants as needed
};

const colors = {
  bgcolor: "#f8f8f9",
  primary: "#212D98",
  secondary: "#6C757D",
  error: "#B21200",
  warning: "#AB6402",
  success: "#12753A",
  btndisabled: "#C5C6C7",
  btnhover: "#6169B6",

  red: "#B90022",
  red1: "#C8172F",
  red2: "#D52035",
  red3: "#E72B3B",
  red4: "#F7363C",
  red5: "#F14B56",
  red6: "#E56F77",
  red7: "#EF979D",
  red8: "#FECCD4",
  red9: "#FFEBEF",

  white: "#fff",

  black: "#282829",
  black1: "#494A4B",
  black2: "#696A6B",
  grey: "#7D7E7F",
  grey1: "#A7A8A9",
  grey2: "#C5C6C7",
  grey3: "#E7E8E9",
  grey4: "#F9FBFC",
  grey5: "#F5F6F7",
  grey6: "#F1F2F3",
};

const theme = createTheme({
  // Add your custom theme configurations here
  breakpoints: {
    values: {
      xs: 0,    // Extra small devices (portrait phones)
      sm: 480,  // Small devices (landscape phones)
      md: 480,  // Medium devices (phones, same as small)
      lg: 480,  // Large devices (tablets, same as small)
      xl: 480,  // Extra large devices (desktops, same as small)
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    background: {
      default: colors.bgcolor,
    },
  },
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F5F6F7',
          color: '#282829',
          fontFamily: '"Inter", sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Override default Button styles here
        root: {
          borderRadius: "4px", // Change border radius for all buttons
          padding: "12px 24px", // Change padding for all buttons
          height: "50px",
          ":disabled": {
            backgroundColor: `${colors.btndisabled}`, // Change background color when disabled
            color: "#ffffff", // Change text color when disabled
          },
          ":hover": {
            backgroundColor: `${colors.btnhover}`, // Change background color on hover
          },
          "&.MuiButton-containedPrimary": {
            backgroundColor: colors.primary, // Change background color for primary button
            color: "#ffffff", // Change text color for primary button
          },
        },
        containedSizeSmall: {
          height: "36px",
          padding: "8px 32px 8px 32px",
        },
        text: {
          ":hover": {
            backgroundColor: "#C8172E",
            color: colors.btnhover,
          },
          ":disabled": {
            backgroundColor: "transparent",
            color: colors.btndisabled,
          },
          // "&.MuiButton-textWarning": {
          //   ":hover": {
          //     backgroundColor: "transparent",
          //     color: colors.warning,
          //   },
          // },
        },
        outlined: {
          border: "2px solid",
          borderColor: colors.primary,
          ":hover": {
            border: "2px solid",
            backgroundColor: "transparent",
            color: colors.btnhover,
            borderColor: colors.btnhover,
          },
          ":disabled": {
            border: "2px solid",
            backgroundColor: "transparent",
            color: colors.btndisabled,
            borderColor: colors.btndisabled,
          },
        },
        outlinedSizeSmall: {
          height: "36px",
          padding: "8px 32px 8px 32px",
        },
        containedPrimary: {
          color: "#fff", // Change text color for primary contained button
        },
      },
    },
  },
});

export default theme;
