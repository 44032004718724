import React from 'react';
import { Box, Typography } from '@mui/material';
import "./Activity.scss";
import verify from "../../assests/images/verify.svg";

// Define the TypeScript interface for the data item
interface ActivityItem {
  time: string;
  htmlContent: any;
}

// Define the TypeScript interface for the component props
interface ActivityProps {
  items: ActivityItem[];
}

const Activity: React.FC<ActivityProps> = ({ items }) => {
  return (
    <div>
      <Box className="activities">
        {items.map((item, index) => {
          return (
            <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "12px", padding: "16px 20px", borderTop: "2px solid #F5F6F7" }}>
              <img className="verify" src={verify} alt="verify" height={40} width={40} />
              <div className='ss' dangerouslySetInnerHTML={{ __html: item.htmlContent }} />
              <Typography variant="caption" sx={{ color: "#475569", marginLeft: "auto" }}>{item.time}</Typography>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default Activity;
