import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icons from '../components/Icons';

const BrandDetails = () => {
    const navigate = useNavigate();

    const images = [
        { src: '/images/detailsImage1.png', alt: 'Description for image 1' },
        { src: '/images/detailsImage2.png', alt: 'Description for image 2' },
    ];

    return (
        <>
            <Box sx={{ marginBottom: "16px", padding: "13px 20px", textAlign: "start", display: "flex", alignItems: "center", gap: "4px" }}>
                <Button onClick={() => navigate('/brand')} sx={{ padding: "0", ":hover": { background: "transparent" }, minWidth: "auto", height: "auto" }}>
                    <Icons name="leftArrowIcon" />
                </Button>
                <Typography sx={{ fontWeight: "500", color: "#3B3B3B", fontSize: "16px", lineHeight: "24px" }}>
                    Go Drippin
                </Typography>
            </Box>
            {images.map((image, index) => (
                <Box
                    key={index}
                    sx={{
                        margin: "0 0px 12px",
                        textAlign: "center",
                        padding: '0 20px'
                    }}
                >
                    <img
                        src={image.src}
                        alt={image.alt}
                        style={{
                            width: "100%",
                            height: "218px",
                            borderRadius: "8px",
                            maxWidth: "335px"
                        }}
                    />
                </Box>
            ))}
        </>
    );
};

export default BrandDetails;
