import React from 'react';
import HomePageComponent from '../components/HomePage/Index';

const HomePage = () => {
    return (
        <>
            <HomePageComponent />
        </>
    );
};

export default HomePage;
