import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
} from "@mui/material";
function Ratings({ stepIndex, handleChange, step, selectedValue }: any) {
  return (
    <>
      <FormControl
        component="fieldset"
        sx={{ display: "flex", alignItems: "center", gap: "12px" }}
      >
        <RadioGroup
          value={selectedValue[stepIndex]?.answer}
          onChange={(e) => handleChange(e, stepIndex)}
          key={stepIndex}
          sx={{ width: "100%" }}
        >
          <Box
            key={stepIndex}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "4px",
              borderRadius: 2,
              border:
                step === selectedValue[stepIndex]?.answer
                  ? "2px solid #FFE3D3"
                  : "1px solid #e0e0e0",
              backgroundColor:
                step === selectedValue[stepIndex]?.answer
                  ? "#FFF6F0"
                  : "transparent",
              marginBottom: "6px",
              cursor: "pointer", // Make the box look clickable
            }}
            onClick={() => handleChange({ target: { value: step } }, stepIndex)} // Trigger selection when Box is clicked
          >
            <FormControlLabel
              value={step}
              key={stepIndex}
              control={<Radio key={stepIndex} sx={{ display: "none" }} />}
              label={
                <Rating
                  value={step}
                  readOnly
                  sx={{ color: "#FFC107", fontSize: "1.5rem"  }}
                />
              }
              sx={{ margin: 0 }}
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default Ratings;
