import React from 'react'
import VideoComponent from '../components/videos'
import Button from "@mui/material/Button";
import { Box } from '@mui/material';
// import Icons from '../Icons';
import NoteCoin from '../components/videos/NoteCoin';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function Video() {
  const [isVideoWatched, setIsVideoWatched] = React.useState<boolean>(false);
  const navigate = useNavigate()
  const { t } = useTranslation();

  const navigation = () => {
    navigate(`/task1`)
  }

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
  };
  return (
    <div>
      <VideoComponent onVideoEnd={handleVideoEnd} />
      <Box className="Video">
        <Box className="foot">
          <Button disabled={!isVideoWatched} onClick={navigation} className="btn_red" >
            {/* Start the Quiz */}
            {t("task_names.start_the_quiz")}
          </Button>
          {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
        </Box>
      </Box>
      <NoteCoin note="Watch the video carefully to complete the quiz" home="home" iconHeight={130} />
    </div>
  )
}

export default Video

