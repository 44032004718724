import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute' as 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  bgcolor: '#FFF',
  borderRadius: '12px 12px 0px 0px',
  padding: '20px',
  boxShadow: 24,
  p: 4,
};

function HoldOn({ show, setShowConfirmation, handleBacked }:any) {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    React.useEffect(()=>{setOpen(show)},[show])
    const handleClose = () =>{ setOpen(false);setShowConfirmation(false)};

    return (
        <div>
            <Modal className="holdOn"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modalContent">
                    <IconButton className='closeBtn'
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h5" > {t("taskdetaitls.hold_on_title")} </Typography>
                    <Typography id="modal-modal-description" variant='body2'> {t("taskdetaitls.hold_on_message")} </Typography>
                    <Box className="modal_cta">
                        <Button onClick={handleBacked} className="red_btn">{t("taskdetaitls.hold_on_yes")}</Button>
                        <Button className="white_btn" onClick={handleClose}>{t("taskdetaitls.hold_on_no")}</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default HoldOn;
