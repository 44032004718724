import React from 'react';
import '../videos/Video.scss';
import Icons from '../Icons';
import { Box, Typography } from '@mui/material';

interface NoteCoinProps {
  note: string;
  iconHeight: number;
  home: string;
  note_label?: string;
}

const NoteCoin: React.FC<NoteCoinProps> = ({ note, iconHeight, home,note_label }) => {
  return (
    <div>
      <Box className="notecoin">
        <Box className="content">
          <Typography className='caption1' variant="caption1">{note_label?note_label:`Note :`}</Typography>
          <Typography className='body1' variant="body1">{note}</Typography>
        </Box>
        <Box className="coinn">
          <Icons name={home} height={iconHeight} />
        </Box>
      </Box>
    </div>
  );
};

export default NoteCoin;
